<template lang="pug">
  observer.slice-ticker-text.text-25.lg_text-40.font-light.h-45.pb-2.lg_h-70.lg_pb-4.flex.items-center.leading-none.uppercase(:threshold="0.5", v-if="slice.items.length", :style="{background: slice.primary.bg_color}", @visible="visible = true", @hidden="visible = false")
    .w-full.overflow-x-hidden
      .nowrap(style="width: 100000px")
        .ticker-tape__body.inline-block(:class="{'ticker-tape__body--animate': visible}", :style="{animationDuration: 40 * slice.items.length + 's'}")
          //- repeat...
          template(v-for="n in 12")
            //- texts...
            template(v-for="(item, i) in slice.items", v-if="item.text")
              //- TODO links don't use router-link...
              prismic-rich-text.inline-block.children-inline(:field="item.text")
              //- space between items
              span.inline-block(v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'")
</template>

<script>
export default {
  name: 'SliceTickerText',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) }
  },
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style>
.ticker-tape__body{
  /* animation duration in style */
  animation: ticker infinite linear;
  animation-play-state: paused;
}
.ticker-tape__body--animate{
  animation-play-state: running;
}
@keyframes ticker {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-50%);
  }
}
</style>
