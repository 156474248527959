export default function (doc) {
  // Return the path depending on Prismic Document's type
  const url = {
    // home
    home: '/',
    // aro
    site: '/aro',
    // archive
    archive: '/archive',
    // archive page
    archive_page: '/archive/' + doc.uid,
    // collection
    collection: '/collections/' + doc.uid,
    // page
    page: '/' + doc.uid
  }
  return url[doc.type] || '/'
}
