<template lang="pug">
  dialog.modal.p-0(@keydown.esc="$emit('close')", :open="open")
    slot
</template>

<script>
let prevFocus
export default {
  name: 'Modal',
  props: ['open'],
  methods: {
    focus () {
      prevFocus = document.activeElement
      this.$nextTick(() => {
        const tabEl = this.$el.querySelector('[tabindex]')
        return tabEl && tabEl.focus()
      })
    },
    revertFocus () {
      return prevFocus && prevFocus.focus()
    }
  },
  mounted () {
    this.focus()
  },
  watch: {
    open (open) {
      return open ? this.focus() : this.revertFocus()
    }
  },
  destroyed () {
    this.revertFocus()
  }
}
</script>

<style>
dialog{
  background:transparent;
}
/* always visible for menu animations */
dialog:not([open]){
  display:block;
  &.flex{
    display:flex;
  }
}
</style>
