<template lang="pug">
  .flex.items-center.leading-none.uppercase.px-15.cursor-pointer(@click="$emit('click')")
    //- (back btn svg)
    button.mr-12(v-if="back", @click.stop="$emit('back')")
      svg-chevron-left.block
    //- crumbs...
    span.flex.items-start.mr-15(v-for="crumb in crumbs")
      //- title
      .inline.transition-font-size.duration-150.whitespace-no-wrap(:class="{'text-28 lg_text-40 lg_leading-none font-light -mt-px lg_-mt-3': titleLarge}", v-html="crumb.title")
      //- (inventory count)
      span.ml-3.font-tabular(v-if="crumb.inventory && !isNaN(crumb.inventory)", :class="{'text-gray-300': countGray}")
        | {{ crumb.inventory }}
</template>

<script>
export default {
  name: 'BreadcrumbText',
  props: ['crumbs', 'back', 'countGray', 'titleLarge']
}
</script>

<style>
</style>
