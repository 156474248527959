<template lang="pug">
  .slice-heading-index.text-25.lg_text-60.h-45.lg_h-80.flex.items-center.px-15.lg_px-17.pb-2.lg_pb-4(v-if="slice.primary.heading.length")
    .flex.items-start
      h2.lg_font-light.leading-none
        | {{ $prismic.asText(slice.primary.heading) }}
        sup {{ slice.primary.superscript_count }}
</template>

<script>
export default {
  name: 'SliceHeadingIndex',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  }
}
</script>

<style>
</style>
