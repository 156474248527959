<template lang="pug">
  //- careful adding padding-y here as it affects SliceCollectionsBar and SlicePopularTagsBar
  //- better to adjust in those components directly
  observer.links-bar.h-50.lg_h-75.flex.w-full.items-center.text-25.lg_text-50.uppercase.font-light.leading-none(:threshold="0.01", @visible="$emit('visible')", style="line-height:1")
    .w-full.overflow-x-scroll.scrollbars-hidden
      .whitespace-no-wrap.pl-15
        slot
</template>

<script>
export default {
  name: 'LinksBar'
}
</script>

<style>
</style>
