import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

const app = new Vue()

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // disable browser handling saved position
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
    // wait for the route leave transition to end
    return new Promise(resolve => {
      this.app.$root.$once('afterRouteLeave', () => {
        const position = savedPosition || { x: 0, y: 0 }
        resolve(position)
      })
    })
  }
})

router.afterEach((to, from) => {
  // ready for Netlify pre-rendering...
  window.prerenderReady = true

  // analytics, delay so metaInfo() titles are set
  setTimeout(() => {
    try {
      app.$gtag.pageview({ page_path: to.path, page_title: document.title })
    } catch {
      // will fail if $gtag hasn't been bootstrapped yet in CookieBanner.vue
    }
  }, 1000)
})

export default router
