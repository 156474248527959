<template lang="pug">
  .slice-text.font-sans.px-15.lg_px-17.mt-12.mb-40(:class="{'lg_w-1x3': slice.slice_label !== 'large', 'lg_text-25': slice.slice_label === 'large'}")
    //- (heading)
    prismic-rich-text.mb-lh(v-if="slice.primary.heading.length", :field="slice.primary.heading")
    //- main text
    prismic-rich-text.children-mt-lh(:field="slice.primary.text")
    //- (read more btn)
    //- button.uppercase(v-if="moreTexts.length && !more", @click="more = true") Read More
    //- (more texts...)
    //- prismic-rich-text.mt-lh.children-mt-lh(v-for="(item, i) in moreTexts", v-show="more", :field="item.text", :key="'i' + i")
</template>

<script>
export default {
  name: 'SliceText',
  props: {
    slice: Object
  }
  // data () {
  //   return {
  //     more: false
  //   }
  // },
  // computed: {
  //   moreTexts () {
  //     return this.slice?.items?.filter(itm => itm.text && itm.text.length) || []
  //   }
  // }
}
</script>

<style>
</style>
