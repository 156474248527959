<template lang="pug">
  collection(:collection="slice.primary.collection.uid", v-if="slice.primary.collection.uid", :isSlice="true")
</template>

<script>
import Collection from '@/views/Collection'
export default {
  name: 'SliceProductsCollection',
  props: {
    slice: Object
  },
  components: { Collection }
}
</script>

<style>
</style>
