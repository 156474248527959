<template lang="pug">
  .app-logo-bar.relative
    .absolute.top-0.left-0
      slot(name="left")
    //- left btn
      button.h-45.w-45.flex.items-center.justify-center.cursor-pointer(@click="$emit('left')", :aria-label="leftLabel")
      slot(name="left")

    .w-full.h-45.flex.items-center.justify-center
      slot(name="center")

    //- right btn
    .absolute.top-0.right-0
      slot(name="right")
    //- button.h-45.w-45.flex.items-center.justify-center.cursor-pointer(@click="$emit('right')", :aria-label="rightLabel", :class="{'invisible': hideRight}")
      slot(name="right")
</template>

<script>
export default {
  name: 'AppLogoBar',
  props: {
    leftLabel: { type: String, default: '' },
    rightLabel: { type: String, default: '' },
    hideRight: { type: Boolean, default: false }
  }
}
</script>

<style>
</style>
