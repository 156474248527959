<template lang="pug">
  //- svg basis:
  //- <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14.4" viewBox="0 0 18 14.4">
  //-   <g id="Group_2651" data-name="Group 2651" transform="translate(-497 -16.3)">
  //-     <path id="Path_717" data-name="Path 717" d="M0,0H18" transform="translate(497 17)" stroke="currentColor" stroke-width="2"/>
  //-     <path id="Path_718" data-name="Path 718" d="M0,0H18" transform="translate(497 30)" stroke="currentColor" stroke-width="2"/>
  //-   </g>
  //- </svg>
  //- .svg-icon-menu.border-t-2.border-b-2.border-current(:style="style", @mouseenter="hover = true", @mouseleave="hover = false")
  svg.svg-icon.menu.flex.flex-col.justify-between(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 18 18", width="17px", height="18px")
    line(x1="0", y1="18%", x2="100%", y2="18%", stroke="currentColor", stroke-width="1.4", :class="{'-translate-y-2px': open, 'group-hover_-translate-y-2px': canHover}").transition-transform.duration-200.transform
    line(x1="0", y1="82%", x2="100%", y2="82%", stroke="currentColor", stroke-width="1.4", :class="{'translate-y-2px': open, 'group-hover_translate-y-2px': canHover}").transition-transform.duration-200.transform
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'IconMenu',
  props: ['open'],
  computed: {
    ...mapState(['canHover'])
  }
}
</script>

<style>
</style>
