<template lang="pug">
  .vimeo-player.trans-opacity-quick(:class="{'opacity-0': invisible, 'absolute overlay': bgSize}")
    //- frame
    .vimeo-player__frame.overflow-hidden-off.pointer-events-none(ref="vimeo", :class="{'relative': !bgSize, 'pb-ar-16x9': !bgSize && !loaded, 'absolute': bgSize}", :style="!bgSize && ratio && {paddingBottom: `calc(100% * ${ratio})`}")
      //- <iframe> video will be injected
</template>

<script>
import Player from '@vimeo/player'
export default {
  name: 'VimeoPlayer',
  props: {
    vimeoURL: { type: String, default: undefined },
    bgSize: { type: String, default: undefined },
    clip: { type: Array, default: () => [] },
    visible: { type: Boolean, default: true },
    // playBtn: { type: Boolean, default: false },
    loop: { type: Boolean, default: true },
    progress: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: true }
    // autoplayFull: { type: Boolean, default: false }
  },
  data () {
    return {
      player: null,
      loaded: false,
      invisible: true,
      elW: 0,
      elH: 0,
      videoW: 0,
      videoH: 0,
      iframe: null,
      playing: null, // null while starting in looped-clip state
      muted: true,
      duration: 0,
      clipActive: false,
      clip2: [] // actual clip data (after validations)
    }
  },
  computed: {
    elRatio () {
      return this.elH / this.elW
    },
    ratio () {
      return this.videoH / this.videoW
    },
    clipEnabled () {
      return this.clip.length >= 2 // this.clip[0] !== undefined && this.clip[1] > this.clip[0]
    }
  },
  watch: {
    visible (vis) {
      if (vis) {
        this.$nextTick(() => this.resizeIframe())
        this.play()
      } else {
        this.pause()
      }
    },
    clip (val, old) {
      // if clip array changed...
      if (JSON.stringify(val) !== JSON.stringify(old)) {
        this.startAtClip()
      }
    }
  },
  mounted () {
    this.measure()
    this.init()
    this.$root.$on('newWindowWidth', this.onNewWindowWidth)
  },
  destroyed () {
    this.$root.$off('newWindowWidth', this.onNewWindowWidth)
  },
  methods: {
    init () {
      if (!this.vimeoURL) { return this.$emit('error', 'No vimeo URL') }
      const options = {
        url: this.vimeoURL,
        // background: true,
        controls: false,
        autoplay: this.autoplay,
        loop: this.loop,
        muted: true,
        autopause: false // pause if another vimeo plays
      }
      if (this.bgSize === 'cover') {
        options.maxheight = this.elH // * 0.8
      } else {
        options.maxwidth = this.elW
      }
      // create player
      this.player = new Player(this.$refs.vimeo, options)
      // error on init
      this.player.ready().catch((err) => {
        console.error(err)
        this.$emit('error', err)
      })
      // events
      this.player.on('loaded', async () => {
        try {
          this.duration = await this.player.getDuration()
          await this.startAtClip(true)
          this.loaded = true
          this.$emit('loaded')
          this.resizeIframe() // resize and reveal
        } catch (e) {
          console.error(e)
        }
      })
      this.player.on('timeupdate', this.onTimeUpdate)
      this.player.on('timeupdate', this.onFirstPlay) // binding to @play is inconsistent !
      this.player.on('ended', () => this.$emit('ended'))
      this.player.on('play', () => this.$emit('playing', { playing: true, clip: this.clipActive }))
      this.player.on('pause', () => this.$emit('playing', { playing: false, clip: this.clipActive }))
    },

    startAtClip (skipZero) {
      this.validateClip()
      this.clipActive = true
      // jump to clip start (if set)
      if (!this.clipEnabled || (skipZero && !this.clip2[0])) { return Promise.resolve() }
      return this.player.setCurrentTime(this.clip2[0]).then(() => this.play())
    },
    validateClip () {
      const defaultClip = [0, 10]
      if (!this.clipEnabled) { return }
      // clip end is too long?
      if (this.clip[1] > this.duration) {
        console.warn(`Vimeo clip end time is longer than duration (${this.duration}s). Default will be used: 0 - 10s.`)
        this.clip2 = defaultClip
      // clip end is less than start
      } else if (this.clip[1] <= this.clip[0]) {
        console.warn(`Vimeo clip start is before end (start: ${this.clip[0]}, end: ${this.clip[1]}). Default will be used: 0 - 10s.`)
        this.clip2 = defaultClip
      } else {
        this.clip2 = this.clip.slice()
      }
    },
    onFirstPlay () {
      /**
       * Function to emit when video has visible imagery
       * - bind to 'timeupdate' because 'play' event fires inconsistently...
      **/
      //
      this.$emit('hasimagery')
      this.$emit('firstplay')
      if (!this.autoplay) {
        this.pause()
      }
      this.player.off('timeupdate', this.onFirstPlay)
    },
    onTimeUpdate (time) {
      if (this.progress) {
        let pct = time.percent * 100
        // progress during clip mode:
        if (this.clipActive) {
          // percent depends on clip start/end...
          const end = time.duration < this.clip2[1] ? this.duration : this.clip2[1]
          pct = (time.seconds - this.clip2[0]) / (end - this.clip2[0])
          pct = Math.min(pct, 1) * 100
        }
        this.$emit('progress', pct)
      }
      // if in clip mode - loop back to clip start on clip end
      if (this.clipActive) {
        const end = this.clip2[1]
        if (end && time.seconds >= end) {
          this.$emit('clipEnd')
          this.player.setCurrentTime(this.clip2[0])
          // pause if no loop
          if (!this.loop) {
            this.pause()
          }
        }
      }
    },

    play () {
      return this.player && this.player.getPaused().then(paused => paused && this.player.play())
    },
    pause () {
      return this.player && this.player.getPaused().then(paused => !paused && this.player.pause())
    },
    async beginFullPlayback () {
      try {
        // unmute (early!)
        this.unmute()
        // disable clip mode, set time to 0
        if (this.clipActive) {
          this.clipActive = false
          await this.player.setCurrentTime(0)
        }
        this.player.setLoop(false)
        this.play()
        this.$emit('playing', { playing: true, clip: false })
      } catch (e) {
        console.error(e)
      }
    },
    async playBtnClick (noPause = false) {
      try {
        const paused = await this.player.getPaused()
        return this.clipActive ? this.beginFullPlayback()
          : !paused && !noPause ? this.pause()
            : this.play()
      } catch (e) {
        console.error(e)
      }
    },

    /* Progress */
    async changeProgress (pct) {
      try {
        const dur = await this.player.getDuration()
        await this.player.setCurrentTime(Number(pct) * dur)
      } catch (err) {
        console.error(err)
      }
    },

    /* Mute / Unmute */
    mute () {
      return this.player.setMuted(true).then((mtd) => {
        this.muted = mtd
        this.$emit('mutechange', mtd)
      })
    },
    unmute () {
      return this.player.setMuted(false).then((mtd) => {
        this.muted = mtd
        this.$emit('mutechange', mtd)
      })
    },
    toggleMute () {
      return this.muted ? this.unmute() : this.mute()
    },

    /* Fullscreen */
    async fullscreen () {
      try {
        await this.player.requestFullscreen()
        // TODO - maybe don't play for iOS since it seems to keep player in bg
        this.playBtnClick(true)
      } catch (e) {
        console.error(e)
      }
    },
    getFullscreen () {
      return this.player.getFullscreen().then(full => full)
    },
    exitFullscreen () {
      return this.player.exitFullscreen()
    },

    /* Resizing */
    async onNewWindowWidth () {
      try {
        const isFullscreen = await this.getFullscreen()
        if (isFullscreen) { return }
        this.measure()
        this.resizeIframe()
      } catch (e) {
        console.error(e)
      }
    },
    measure () {
      this.elW = this.$el.offsetWidth
      this.elH = this.$el.offsetHeight
    },
    resizeIframe () {
      this.measure()
      this.iframe = this.iframe || this.$el.querySelector('iframe')
      const iframe = this.iframe
      const size = this.bgSize
      if (!iframe) { return console.warn('No iframe!') }
      // get aspect ratio
      if (!this.videoW) {
        this.videoW = iframe.getAttribute('width')
        this.videoH = iframe.getAttribute('height')
      }
      // resize (if background)
      if (size) {
        const vidIsWider = this.ratio <= this.elRatio // video wider than frame
        const scaleByHt = (size === 'cover' && vidIsWider) || (size === 'contain' && !vidIsWider)
        // apply
        if (scaleByHt) {
          const w = this.elH * this.videoW / this.videoH
          iframe.setAttribute('height', this.elH)
          iframe.setAttribute('width', parseInt(w))
        } else {
          const h = this.elW * this.videoH / this.videoW
          iframe.setAttribute('height', parseInt(h))
          iframe.setAttribute('width', this.elW)
        }
      }
      this.invisible = false
    }
  }
}
</script>

<style>
.vimeo-player__frame{
  &.absolute{
    top:50%; left:50%;
    transform: translate(-50%, -50%);
  }
  & iframe {
    /*transition: filter 600ms;*/
    /*pointer-events: none !important;*/
  }
  /*&.vimeo-player__frame--blurred iframe{
    filter:blur(6px);
  }*/
  &.relative iframe{
    position: absolute;
    top:0; left:0;
    width:100% !important;
    height:100% !important;
  }
}
</style>
