import Vue from 'vue'
import Vuex from 'vuex'
import shop from './shop'
import kb from 'lodash/kebabCase'

const vue = new Vue()
Vue.use(Vuex)

export default new Vuex.Store({
  modules: { shop },
  state: {
    // TODO - set to true, and use beforeRtEnter on collections to disable...
    landingActive: false,
    // site: null,
    home: null,
    archive: null,
    aro: null,
    collections: [],
    activeTags: [],

    canHover: window.matchMedia('(hover:hover)').matches,
    winW: window.innerWidth
  },
  getters: {
    is: (state) => (ctx) => {
      return ctx === 'lg' ? state.winW >= 1024
        : false
    },
    inventory: (state) => (uid) => {
      const inv = state.collections.find(doc => doc.uid === uid)?._inventory ?? '-'
      return formatInventory(inv)
    },
    meta: state => ({ title, descrip, img }) => {
      const meta = []
      const siteTitle = state.aro?.title[0]?.text || 'Aro Archive'
      title = title ? `${title} - ${siteTitle}` : siteTitle
      descrip = descrip || state.aro?.meta_description || ''
      img = img || state.aro?.aro_meta_image?.url
      // add
      meta.push({ property: 'og:title', content: title })
      meta.push({ property: 'og:site_name', content: siteTitle })
      meta.push({ property: 'og:type', content: 'website' })
      meta.push({ name: 'description', content: descrip })
      meta.push({ property: 'og:description', content: descrip })
      if (img) {
        meta.push({ property: 'og:image', content: img })
      }
      // meta.push({ property: 'og:url', content: ##ADDCANNONICAL## })
      return meta
    },
    activeFilters: () => ({ query }) => {
      /**
       * transform route query into array of { key, value }
      */
      // from: {type: 'dresses,coats,socks', color: 'white'}
      // to: [{key: 'type', value: 'dresses'}, ...]
      return Object.keys(query).reduce((acc, key) => acc.concat(query[key].split(',').map(value => ({ key, value }))), [])
    }
  },
  mutations: {
    setHome (state, doc) {
      state.home = doc
    },
    setArchive (state, doc) {
      state.archive = doc
    },
    setAro (state, doc) {
      state.aro = doc
    },
    addCollections (state, docs) {
      docs = docs.map(doc => formatCollection(doc))
      state.collections = state.collections.concat(docs)
    },
    setLanding (state, val) {
      state.landingActive = val
    },
    setActiveTags (state, tags) {
      state.activeTags = tags
    },
    setWinW (state, w) {
      state.winW = w
    }
  },
  actions: {
    async getHome ({ state, commit }) {
      let doc = state.home
      if (!doc) {
        doc = (await vue.$prismic.client.getSingle('home')).data
        commit('setHome', doc)
      }
      return doc
    },

    async getAro ({ state, commit, dispatch }) {
      let doc = state.aro
      if (!doc) {
        doc = (await vue.$prismic.client.getSingle('site', { fetchLinks: ['collection.title'] })).data
        commit('setAro', doc)
        dispatch('getLinkedCollections', doc)
      }
      return doc
    },

    async getArchive ({ state, commit, dispatch }) {
      let doc = state.archive
      if (!doc) {
        doc = (await vue.$prismic.client.getSingle('archive')).data
        commit('setArchive', doc)
      }
      return doc
    },

    async getArchivePage (_, uid) {
      const doc = (await prismic(vue.$prismic.Predicates.at('my.archive_page.uid', uid))).results[0]
      return doc && doc.data
    },

    async getLinkedCollections ({ commit }, doc) {
      // fetch linked collections (for inventory counts)
      if (!doc) return Promise.reject(new Error('No doc'))
      let ids = []
      ids = ids.concat(getCollectionIds(doc.menu_primary))
      ids = ids.concat(getCollectionIds(doc.menu_collections))
      ids = ids.concat(getCollectionIds(doc.landing))
      ids = ids.concat(getCollectionIds(doc.body))
      if (ids.length) {
        const docs = (await prismic(vue.$prismic.Predicates.in('document.id', ids), { pageSize: 100 })).results
        if (docs.length) commit('addCollections', docs)
      }
    },

    async getCollection ({ state, commit }, uid) {
      let doc = state.collections.find(col => col.uid === uid)
      if (!doc) {
        doc = (await prismic(vue.$prismic.Predicates.at('my.collection.uid', uid))).results[0]
        if (doc) {
          doc = formatCollection(doc)
          commit('addCollections', [doc])
        }
      }
      return doc
    },

    async subscribeToNewsletter (_, email) {
      try {
        let resp = await fetch('/api/mailchimp-subscribe', { method: 'POST', body: JSON.stringify({ email }) })
        resp = await resp.json()
        if (resp.title === 'Member Exists') {
          return 'Already subscribed'
        }
        return 'Success'
      } catch (e) {
        console.error('@subscribeToNewsletter', e)
        return 'Error'
      }
    }
  }
})

// HELPERS

const prismic = async (qry, options) => {
  try {
    return await vue.$prismic.client.query(qry, options)
  } catch (e) {
    console.error('Prismic API Error:', e)
  }
}

// Pull collection ids from group field or slices body
function getCollectionIds (arr) {
  const ids = []
  const pushIds = obj => obj && Object.keys(obj).filter(key => obj[key]?.type === 'collection').forEach(key => ids.push(obj[key].id))
  arr.forEach(row => {
    pushIds(row) // for group fields (menu)
    pushIds(row.primary) // slice.primary
    return row.items && row.items.forEach(item => pushIds(item)) // slice.items
  })
  return ids
}

function formatCollection (doc) {
  // add collections with inventory counts ("product" slices)
  return {
    ...doc,
    _inventory: doc.data.body.filter(s => s.slice_type === 'product' && s.primary.shopify_product).length,
    _tags: getShopifyTagsFromCollectionProductSlices(doc)
  }
}

function getShopifyTagsFromCollectionProductSlices (doc) {
  // collect tags from product slices
  let tags = []

  if (doc) {
    tags = {}
    // get all products from collection doc's slices...
    const products = doc.data.body
      .filter(s => s.slice_type === 'product') // only product slices
      .map(s => s.primary.shopify_product) // get shopify_product data
      .filter(prd => prd && prd.tags) // filter for ones that have tags data

    // loop through products...
    products.forEach(product => {
      // loop through each product's tags...
      product.tags.split(',')
        .filter(tag => tag.includes(':')) // only prefixed tags (for filters menu) "designer:..."
        .forEach(tag => {
          tag = tag.trim()
          if (!tags[tag]) tags[tag] = 1 // create tag object
          else tags[tag]++ // increment
        })
    })

    // transform `tags` object into array of tag objects
    tags = Object.keys(tags).sort().map(key => {
      const name = key.split(':')[1]
      return {
        group: key.split(':')[0].toLowerCase(), // "colour"
        name: name, // "Red"
        slug: kb(name), // "red"
        inventory: tags[key] // qty from tag value in tags object
      }
    })
  }

  return tags
}

export function formatInventory (value) {
  return (!isNaN(value) && value < 10) ? ('0' + value).slice(-2) // add 0 in front of single digits
    : value
}
