<!-- revision to mono -->
<!-- revision (back) 2021.03.11 - back to headline (like menu) -->
<!-- revision 2021.06.10 - font size to inherit from base -->
<template lang="pug">
  span.btn.flex.items-center.justify-center.h-45.px-17.uppercase.font-sans.text-14
    slot
</template>

<script>
export default {
  name: 'Btn'
}
</script>

<style>
</style>
