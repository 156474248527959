<template lang="pug">
  modal.cart.flex.flex-col.uppercase.font-mono.text-mono-12(v-on="$listeners", v-bind="$attrs")
    h5.sr-only(tabindex="0") Bag
    template(v-if="checkout")
      .flex-1.flex-1.overflow-y-scroll.scrollbars-hidden.flex.flex-col
        ul.flex-1
          //- items...
          li(v-for="(item, i) in checkout.lineItems", :class="{'bg-gray-100': i % 2 === 0}")
            cart-line-item(:item="item", :key="item.id")
        footer.sticky.bottom-0.left-0.w-full.bg-white.border-t.border-gray-100
          .py-15.mx-15
            table.w-full.border-none
              tr
                td Subtotal
                td.text-right {{ price(checkout.subtotalPriceV2.amount, false) }}
              tr
                td
                  | Delivery
                  span.ml-8 (Calculated at checkout)
              //- removed 2021.02 slack note
              //- tr
                td
                  | Tax
                  span.text-gray-300.ml-8 (UK 20% Inc)
            prismic-rich-text(:field="site.cart_footer_text", v-if="site") Free shipping on all orders over £200
      a.block.bg-black.text-white(:href="checkout.webUrl", target="_blank", rel="noopener nofollow")
        btn Proceed to Checkout
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CartLineItem from './CartLineItem'
export default {
  name: 'Cart',
  computed: {
    ...mapState({ site: state => state.site, checkout: state => state.shop.checkout }),
    ...mapGetters({ price: 'shop/price' })
  },
  mounted () {
    this.$store.dispatch('shop/fetchCheckout')
  },
  components: { CartLineItem }
}
</script>

<style>
.cart table, .cart tr, .cart td {
  border: none;
}
</style>
