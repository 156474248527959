<template lang="pug">
  main#main.focus_outline-none(role="main", tabindex="-1")
    slot
</template>

<script>
export default {
  name: 'MainEl'
}
</script>

<style>
</style>
