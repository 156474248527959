<template lang="pug">
  //- xs: text-left
  //- md: flex row
  footer.app-footer.font-mono.text-mono-12.uppercase
    nav.md_flex.px-8.py-8.flex-wrap.md_text-center.md_p-0.bg-gray-100.text-black
      //- (footer links from prismic)
      template(v-if="aro")
        app-link(v-for="(item, i) in aro.footer", :key="i", :link="item.link", :class="[itmClss]") {{ item.link_text }}
      //- credits btn
      button(:class="[{'hidden': credits}, itmClss]", @click="toggleCredits")
        span.md_hidden Credits
        span.hidden.md_inline Site Credits
      //- (credits)
      .w-full.md_flex-auto.md_flex.md_justify-end.md_px-15.bg-white(v-show="credits", :class="itmClss")
        span Design: #[a.btn-theme-text(href="http://spencerfenton.co.uk", target="_blank", rel="noopener") Spencer Fenton], Build: #[a.btn-theme-text(href="http://everettwilliams.info", target="_blank", rel="noopener") Everett Williams]
        //- span(:class="itmClss")
        //-   span Design: #[a(href="http://spencerfenton.co.uk", target="_blank", rel="noopener") Spencer Fenton]
        //- span(:class="itmClss")
        //-   span Build: #[a(href="http://everettwilliams.info", target="_blank", rel="noopener") Everett Williams]
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AroFooter',
  data () {
    return {
      credits: false,
      itmClss: 'block px-8 py-5 md_p-0 md_flex items-center justify-center md_h-45 md_flex-1'
    }
  },
  computed: {
    ...mapState(['aro'])
  },
  methods: {
    isLeft (i) {
      const ttl = this.aro?.footer?.length + 1
      return i + 1 <= (ttl / 2)
    },
    async toggleCredits () {
      this.credits = !this.credits
      if (this.credits) {
        await this.$nextTick()
        window.scroll(0, 100000000)
      }
    }
  }
}
</script>

<style>
</style>
