<template lang="pug">
  product-thumb.slice-product.w-1x2.md_w-1x3.lg_w-1x4(v-if="product", :doc="product", :images="product.images", :price="variant.price", :key="product.handle")
</template>

<script>
export default {
  name: 'SliceProduct',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  computed: {
    product () {
      return this.slice?.primary?.shopify_product
    },
    variant () {
      return this.product?.variants.slice().sort((a, b) => a.price - b.price)[0]
    }
  }
}
</script>

<style>
</style>
