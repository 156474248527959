<template lang="pug">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.22 14.319" width="8.22px" height="14.319px" preserveAspectRatio>
    <path id="Path_1210" data-name="Path 1210" d="M-9379.165,5230.258l6.629,6.629,6.63-6.629" transform="translate(5237.948 9379.695) rotate(90)" fill="none" stroke="currentColor" stroke-width="1.4"/>
  </svg>
</template>

<script>
export default {
  name: 'SVG-ChevronLeft'
}
</script>

<style>
</style>
