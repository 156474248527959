<template lang="pug">
  .landing.lg_min-h-screen.bg-black.relative
    slice-body(v-if="aro", :slices="aro.landing")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Landing',
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(['aro'])
  },
  created () {
    if (!this.aro) this.$store.commit('setLanding', true)
  },
  destroyed () {
    this.$store.commit('setLanding', false)
  }
}
</script>

<style>
</style>
