<template lang="pug">
  //- base font size: header/menu type
  #app.text-14.lg_text-15.font-sans.font-normal.bg-white
    accessibility-links
    cookie-banner.transform.transition-transform.duration-150(:class="{'translate-y-full': intro}")
    transition(:name="transition", @after-leave="afterRouteLeave", @after-enter="intro = false")
      router-view(:key="viewKey")
</template>

<script>
import { mapState } from 'vuex'
import '@/style/_main.css'
import AccessibilityLinks from '@/components/accessibility/AccessibilityLinks'
import CookieBanner from '@/components/CookieBanner'
import throttle from 'lodash/throttle'
export default {
  name: 'App',
  metaInfo () {
    return {
      titleTemplate: (pageTitle) => {
        const siteTitle = this.aro?.title[0]?.text || 'Aro Archive'
        return pageTitle ? `${pageTitle} - ${siteTitle}` : `${siteTitle}`
      }
    }
  },
  data () {
    return {
      intro: true,
      transition: 'whitewash-right'
    }
  },
  watch: {
    '$route' (to, from) {
      // set transition
      const color = to.meta.wash || from.meta.wash || 'white'
      this.transition = `${color}wash-right`
    }
  },
  computed: {
    ...mapState(['aro', 'winW']),
    viewKey () {
      return this.$route.name?.split('-')[0]
    }
  },
  methods: {
    afterRouteLeave () {
      this.$root.$emit('afterRouteLeave')
      setTimeout(() => this.$root.$emit('afterRouteLeave2'), 50)
    },
    onResize: throttle(function () {
      const w = window.innerWidth
      if (w !== this.winW) {
        this.$store.commit('setWinW', w)
        this.$root.$emit('newWindowWidth')
      }
    }, 100)
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  components: { AccessibilityLinks, CookieBanner }
}
</script>

<style lang="postcss">
html,body{
  /*scroll-snap-type: y proximity;*/
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
*  PAGE TRANSITION: WHITEWASH-RIGHT
*  basis: https://www.showstudio.com
*/
.whitewash-right-leave-active,
.whitewash-right-enter-active,
.blackwash-right-leave-active,
.blackwash-right-enter-active{
  /* white layer */
  &:after{
    content:'';
    @apply fixed block top-0 left-0 h-full w-full bg-white;
    z-index: 55;
  }
}
.blackwash-right-leave-active,
.blackwash-right-enter-active{
  /* black layer */
  &:after{
    @apply bg-black;
  }
}

/* WASH LEAVE... */
.whitewash-right-leave-active,
.blackwash-right-leave-active{
  /* (fake) animation duration */
  transition: visibility 0s 300ms;
  /* animate white layer */
  &:after{
    transition: transform 300ms;
    transform-origin: top left;
  }
}
.whitewash-right-leave:after,
.blackwash-right-leave:after{
  transform: scale(0,1);
}
.whitewash-right-leave-to,
.blackwash-right-leave-to{
  visibility: hidden;
}

/* WASH ENTER... */
.whitewash-right-enter-active,
.blackwash-right-enter-active{
  /* (fake animation duration, wait for leave) */
  transition: visibility 0s 300ms, background 0s 1200ms;
  /* animate white layer */
  &:after{
    transform-origin:top right;
    transition: transform 600ms 600ms;
  }
}
.whitewash-right-enter,
.blackwash-right-enter{
  visibility: hidden;
  background:white;
}
.whitewash-right-enter-to,
.blackwash-right-enter-to{
  visibility: visible;
  background:transparent;
  &:after{
    transform: scale(0,1);
  }
}

.icon-menu{
  font-size:1.25rem;
  height: 1em;
  width:calc(17/12.5 * 1em);
  background-size:contain;
  background-position: center center;
  background-repeat:no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12.5' viewBox='0 0 17 12.5'%3E%3Cg id='Component_10_92' data-name='Component 10 – 92' transform='translate(0 1)'%3E%3Cpath id='Path_717' data-name='Path 717' d='M0,0H17' fill='none' stroke='%23000' stroke-width='1.4'/%3E%3Cpath id='Path_718' data-name='Path 718' d='M0,0H17' transform='translate(0 10.5)' fill='none' stroke='%23000' stroke-width='1.4'/%3E%3C/g%3E%3C/svg%3E%0A");
  &.icon-menu--close{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.196' height='14.197' viewBox='0 0 14.196 14.197'%3E%3Cg id='Group_2899' data-name='Group 2899' transform='translate(-533.613 -953.003)'%3E%3Cg id='Group_2899-2' data-name='Group 2899'%3E%3Cpath id='Path_1342' data-name='Path 1342' d='M534.143,953.533l13.135,13.136' fill='none' stroke='black' stroke-width='1.4'/%3E%3C/g%3E%3Cpath id='Path_717' data-name='Path 717' d='M534.143,966.669l13.135-13.136' fill='none' stroke='black' stroke-width='1.4'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &.icon-menu--white{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12.5' viewBox='0 0 17 12.5'%3E%3Cg id='Component_10_92' data-name='Component 10 – 92' transform='translate(0 1)'%3E%3Cpath id='Path_717' data-name='Path 717' d='M0,0H17' fill='none' stroke='white' stroke-width='1.4'/%3E%3Cpath id='Path_718' data-name='Path 718' d='M0,0H17' transform='translate(0 10.5)' fill='none' stroke='white' stroke-width='1.4'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &.icon-menu--white--close{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.196' height='14.197' viewBox='0 0 14.196 14.197'%3E%3Cg id='Group_2899' data-name='Group 2899' transform='translate(-533.613 -953.003)'%3E%3Cg id='Group_2899-2' data-name='Group 2899'%3E%3Cpath id='Path_1342' data-name='Path 1342' d='M534.143,953.533l13.135,13.136' fill='none' stroke='%23fff' stroke-width='1.4'/%3E%3C/g%3E%3Cpath id='Path_717' data-name='Path 717' d='M534.143,966.669l13.135-13.136' fill='none' stroke='%23fff' stroke-width='1.4'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
/* bag */
.icon-bag{
  font-size:1.7rem;
  height:1em;
  width:calc(16/17 * 1em);
  background-image: url(~@/assets/aro-icon-bag.svg);
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17.011' viewBox='0 0 16 17.011'%3E%3Cg id='Group_2360' data-name='Group 2360' transform='translate(-927 -14.15)'%3E%3Cg id='Group_2359' data-name='Group 2359' transform='translate(637.292 -399.03)'%3E%3Cg id='Rectangle_730' data-name='Rectangle 730'%3E%3Crect id='Rectangle_876' data-name='Rectangle 876' width='16' height='13' transform='translate(289.708 417.191)' fill='none'/%3E%3Crect id='Rectangle_877' data-name='Rectangle 877' width='14.3' height='11.3' transform='translate(290.558 418.041)' fill='none' stroke='%23040405' stroke-width='1.7'/%3E%3C/g%3E%3Cpath id='Path_1047' data-name='Path 1047' d='M294.316,417.352a3.322,3.322,0,0,1,6.644,0' fill='none' stroke='%23040405' stroke-width='1.7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");*/
  background-size:contain;
  background-repeat: no-repeat;

}
/* bag - white for bag-active state */
/* .bg-black > .icon-bag{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17.011' viewBox='0 0 16 17.011'%3E%3Cg id='Group_2360' data-name='Group 2360' transform='translate(-927 -14.15)'%3E%3Cg id='Group_2359' data-name='Group 2359' transform='translate(637.292 -399.03)'%3E%3Cg id='Rectangle_730' data-name='Rectangle 730'%3E%3Crect id='Rectangle_876' data-name='Rectangle 876' width='16' height='13' transform='translate(289.708 417.191)' fill='none'/%3E%3Crect id='Rectangle_877' data-name='Rectangle 877' width='14.3' height='11.3' transform='translate(290.558 418.041)' fill='none' stroke='white' stroke-width='1.7'/%3E%3C/g%3E%3Cpath id='Path_1047' data-name='Path 1047' d='M294.316,417.352a3.322,3.322,0,0,1,6.644,0' fill='none' stroke='white' stroke-width='1.7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
} */

.icon-search{
  font-size:1.7rem;
  height:1em;
  width:calc(16.919/17 * 1em);
  background-image: url(~@/assets/icon-search.svg);
  /*background-image: url("data:image/svg+xml,%3Csvg height='16.718' viewBox='0 0 17.134 16.718' width='17.134' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23000' stroke-width='1.5' transform='translate(-.026)'%3E%3Cg%3E%3Ccircle cx='8.026' cy='8' r='8' stroke='none'/%3E%3Ccircle cx='8.026' cy='8' fill='none' r='7.25'/%3E%3C/g%3E%3Cpath d='m-4046.158 5286.444 3.91 4.039' transform='translate(4058.87 -5274.287)'/%3E%3C/g%3E%3C/svg%3E");*/
  background-size:contain;
  background-repeat: no-repeat;
}

/* using .vue componentn */
/*.icon-chevron-left{
  height:1em;
  width:calc(8.22/14.319*1em);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.22 14.319'%3E%3Cpath id='Path_1210' data-name='Path 1210' d='M-9379.165,5230.258l6.629,6.629,6.63-6.629' transform='translate(5237.948 9379.695) rotate(90)' fill='none' stroke='white' stroke-width='1.2'/%3E%3C/svg%3E");
  background-size:contain;
  background-repeat: no-repeat;
}*/

</style>
