<template lang="pug">
  observer.slice-tile-carousel.relative.text-white.md_font-light.group.leading-1p1.md_text-30(:threshold="0.1", @visible="visible = true", @hidden="visible = false")
    //- carousel
    .flickity-carousel.relative.transform.duration-200(ref="carousel")
      //- items...
      .carousel-cell.w-1x2.md_w-1x3(v-for="(item, i) in slice.items", @click="onCellClick(item)")
        .relative.w-full.pb-ar-2x3.pointer-events-none
          resp-img(:bg="true", :image="item.image")
          h6.absolute.top-0.left-0.w-full.px-15.md_px-17.pt-10.md_pt-8
            router-link(:to="linkTo(item)") {{ item.link_text }}
    //- tap areas
    nav(v-if="slice.items.length > 1")
      button.absolute.left-0.top-0.h-full.w-50.lg_w-80.z-10(@click="arrowClick('previous')", style="cursor:w-resize")
      button.absolute.right-0.top-0.h-full.w-50.lg_w-80.z-10(@click="arrowClick('next')", style="cursor:e-resize")
</template>

<script>
import kb from 'lodash/kebabCase'
import 'flickity/css/flickity.css'
import Flickity from 'flickity'
export default {
  name: 'SliceTileCarousel',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) }
  },
  data () {
    return {
      flkty: null,
      visible: false,
      click: false
    }
  },
  methods: {
    init () {
      this.flkty = new Flickity(this.$refs.carousel, {
        cellAlign: 'left',
        draggable: '>1',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 1,
        autoPlay: 3000, // duration
        pauseAutoPlayOnHover: false,
        // animation speed
        selectedAttraction: 0.075, // 0.025
        friction: 0.42 // 0.28 (default)
        // higher attraction and higher friction
        // faster transitions
      })
      if (!this.visible) this.flkty.pausePlayer()
      // detect static clicks for routing
      this.flkty.on('staticClick', () => { this.click = true })
      // resume autoplay after drag
      this.flkty.on('dragEnd', () => this.flkty.playPlayer())
    },
    linkTo (item) {
      let query = {}
      let tag = item.shopify_tag?.toLowerCase() // 'Colour:Black'
      if (tag) {
        // prefixed tags...
        if (tag.includes(':')) {
          tag = tag.split(':')
          const key = kb(tag[0]) // colour
          const value = kb(tag[1]) // black
          query[key] = value // { colour: "black" }
        } else {
          // unprefixed = 'type'
          query = { type: tag }
        }
      }
      return {
        name: 'aro-collection',
        params: { collection: item.collection.uid },
        query
      }
    },
    onCellClick (item) {
      if (this.click) {
        this.$router.push(this.linkTo(item))
      }
      this.click = false
    },
    arrowClick (dir = 'next') {
      this.flkty[dir]() // next/previous
      // reset autoplay timer:
      this.flkty.stopPlayer()
      this.flkty.playPlayer()
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    visible (vis) {
      // play/pause on visibility
      return vis ? this.flkty?.unpausePlayer() : this.flkty?.pausePlayer()
    }
  }
}
</script>

<style>
</style>
