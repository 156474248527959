import { shopify, queryProducts, queryProduct, queryCheckout, mutateCreateCheckout, mutateCheckoutItems } from './shopify-queries'

export default {
  namespaced: true,
  state: {
    checkout: null,
    checkoutUpdating: false
  },
  getters: {
    cartTotal (state) {
      return !state.checkout ? null : state.checkout.lineItems.reduce((acc, cur) => acc + cur.quantity, 0)
    },
    price: (state, getters) => (amnt, clean = true) => {
      if (isNaN(amnt)) return 'N/A'
      amnt = '£' + commaNum(Number(amnt).toFixed(2).toString())
      return !clean ? amnt : amnt.replace('.00', '')
    }
  },
  mutations: {
    setCheckoutUpdating (state, bool = true) {
      state.checkoutUpdating = bool
    },
    setCheckout (state, chk) {
      chk.lineItems = chk.lineItems.edges.map(edge => edge.node) // format
      state.checkout = chk
      localStorage.setItem('checkoutId', chk.id)
    },
    clearCheckout (state) {
      state.checkout = null
    }
  },
  actions: {
    async getProducts ({ getters }, { first = 160, query, after, fullBody = false }) {
      // query = query && 'query=' + query // add query prefix if set
      const qry = {
        query: queryProducts(fullBody),
        variables: { first, query, after }
      }
      const resp = await shopify(qry).catch(onErr)
      return resp?.data?.products
    },

    async getProduct (_, { handle, imgMaxW }) {
      const qry = {
        query: queryProduct,
        variables: { handle, imgMaxW }
      }
      const resp = await shopify(qry).catch(onErr)
      return resp?.data?.productByHandle
    },

    async fetchCheckout ({ commit }) {
      const id = localStorage.getItem('checkoutId')
      if (!id) return null
      const qry = {
        query: queryCheckout,
        variables: { id }
      }
      const resp = await shopify(qry).catch(onErr)
      if (resp.errors) {
        console.error(resp.errors)
        return Promise.reject(new Error('fetchCheckout query had an error'))
      }
      const chk = resp?.data?.node
      // errors ?
      const reset = !chk ? 'Missing checkout'
        : chk.completedAt ? 'Checkout completed: ' + chk.completedAt
          : chk.lineItems?.edges?.find(itm => !itm.node?.variant) ? 'Checkout contains deleted products'
            : null
      // reset
      if (reset) {
        console.warn(reset, '- Destroying saved checkout')
        localStorage.removeItem('checkoutId')
        commit('clearCheckout') // reset
        return null
      }
      // save
      commit('setCheckout', chk)
      return chk
    },

    async newCheckout ({ commit }, lineItems) {
      const qry = {
        query: mutateCreateCheckout,
        variables: { input: { lineItems } }
      }
      commit('setCheckoutUpdating')
      const resp = await shopify(qry).catch(onErr)
      const chk = resp.data?.checkoutCreate?.checkout
      if (chk) {
        commit('setCheckout', chk)
        return chk
      }
      commit('setCheckoutUpdating', false)
      return false
    },

    async updateCheckoutItems ({ state, commit, dispatch }, lineItems) {
      if (!state.checkout) return dispatch('newCheckout', lineItems)
      const qry = {
        query: mutateCheckoutItems,
        variables: { id: state.checkout.id, lineItems }
      }
      commit('setCheckoutUpdating')
      const resp = await shopify(qry).catch(onErr)
      if (resp.errors) onErr(resp.errors)
      const chk = resp.data?.checkoutLineItemsReplace?.checkout
      if (chk) {
        commit('setCheckout', chk)
      }
      commit('setCheckoutUpdating', false)
      return chk
    },

    async addItemToCheckout ({ state, dispatch }, { variantId, quantity, customAttributes }) {
      console.log(customAttributes)
      // no checkout? create new with items...
      if (!state.checkout) {
        return dispatch('newCheckout', [{ variantId, quantity, customAttributes }])
      }
      // check if variant exists in checkout...
      const newItems = state.checkout.lineItems.map(itm => ({ variantId: itm.variant.id, quantity: itm.quantity, customAttributes }))
      const i = newItems.findIndex(itm => itm.variantId === variantId)
      if (i > -1) {
        newItems[i].quantity += quantity // add quantity
      } else {
        newItems.push({ variantId, quantity })
      }
      return dispatch('updateCheckoutItems', newItems)
    }
  }
}

// error handler
const onErr = e => console.error('Shopify API Error:', e)

function commaNum (val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
  }
  return val
}
