<template lang="pug">

  header.aro-header

    //- "headerTop" observer (for changing filterBtn and small breadcrumb white/black)
    observer.absolute.top-0.left-0.w-px.h-80(:threshold="0.01", @visible="headerTop = true", @hidden="headerTop = false")

    //- fixed overlay layer
    nav.fixed.z-30.overlay.flex.pointer-events-none(:style="{height: winH + 'px'}")
      //- logo
      .absolute.z-20.top-0.left-0.w-full.flex.justify-center.h-45.items-center
        router-link.px-15.py-10.pointer-events-auto(:to="{name: 'home'}")
          logo.text-logo-17.block.transform.transition-transform.duration-60.origin-top-center

      //- white column/mobile-overlay
      .w-full.lg_w-1x3.relative.z-10.mx-auto.flex.flex-col.transition-opacity.duration-75(:class="{'opacity-0': landingActive, 'delay-75off': !landingActive, 'shadow': menu}")
        //- top row
        app-logo-bar.relative.z-10.bg-white.pointer-events-auto(ref="toprow")
          //- menu btn
          button.px-15.h-45.flex.items-center.justify-center.group(slot="left", @click="toggle('menu')")
            //- span.icon-menu.block
            svg-aro-menu(:open="menu")
          //- right btns
          .flex.pr-10(slot="right")
            //- search btn
            button.px-4.h-45.flex.items-center.justify-center(@click="onSearchBtnClick", aria-label="Search")
              span.icon-search.block
            //- cart btn
            button.px-4.h-45.flex.items-center.justify-center(v-show="cartTotal", @click="toggle('cart')", aria-label="View Cart")
              span.icon-bag.block

        //- (search input)
        search-input-row.pointer-events-auto(ref="siteSearch", v-show="searchVisible")

        //- (preview primary menu - aro-index intro)
        transition(name="previewmenu")
          aro-header-menu-primary.pointer-events-auto.-mb-45(v-if="$route.name === 'aro-index' && previewMenu", :active="menuCursorHoverIndex", @hover="$event => { menuCursorHoverIndex = $event }", @mouseenter.native="hidePreviewMenuAfterDelay(2000)", @mouseleave.native="cancelHidePreviewMenu", @mounted="hidePreviewMenuAfterDelay(3000)")

        //- (body, expanding)
        .flex-1.flex.pointer-events-auto.relative.overflow-hidden.lg_transition-max-h.lg_duration-400.ease-expo(:style="{maxHeight: !menu ? '0' : menuHt}")
          //- inner (height is preset by window b/c iOS...)
          .relative.flex-1(:style="{height: menuHt}")
            //- cart
            cart.absolute.overlay.transition-opacity.bg-white.inset-shadow-t(:open="menu === 'cart'", @close="menu = false", :class="{'opacity-0 delay-400 pointer-events-none': menu !== 'cart'}")

            //- search
            search-menu-panel.absolute.overlay.transition-opacity.bg-white(:open="menu === 'search'", @close="menu = false", :class="{'opacity-0 delay-400 pointer-events-none': menu !== 'search'}")

            //- main menu
            modal.absolute.overlay.flex.transition-opacity.bg-white(:open="menu === 'menu'", @close="menu = false", v-if="aro", :class="{'opacity-0 delay-400 pointer-events-none': menu !== 'menu'}")
              nav.flex.flex-col.w-full(role="navigation", @mouseleave="menuCursorHoverIndex = -1")
                //- primary menu
                aro-header-menu-primary(:active="menuCursorHoverIndex", @hover="$event => { menuCursorHoverIndex = $event }")
                //- ul.flex.h-45.relative.flex.bg-gray-100.py-2
                  //- primary menu items...
                  li.w-1x3.flex(v-for="(item, i) in aro.menu_primary", @mouseenter="menuCursorHoverIndex = i")
                    app-link.aro-header__primary-link.flex.items-center.justify-center.w-full(:link="item.link", :class="{'border-l border-white': i}")
                      span.border-b.border-transparent(:class="{'border-current': menuCursorIndex === i}", style="line-height:1.25") {{ item.link_text }}
                    //- button.text-center.h-45.w-full.flex.items-center.justify-center(@click.stop="menuCursorHoverIndex = i") {{ item.link_text }}
                  //- menu cursor
                  //- .absolute.bottom-0.left-0.h-px.bg-black.transform.transition-transform.duration-100(:style="{width: `calc(100% / ${aro.menu_primary.length})`, transform: `translateX(${100 * menuCursorIndex}%)`}", v-show="menuCursorIndex >= 0")

                //- filters
                .relative.flex-1.uppercase
                  //- sublists...
                  .absolute.overlay.overflow-y-scroll.scrollbars-hidden.bg-white.flex(v-for="(item, i) in aro.menu_primary", v-show="menuCursorIndex === i")
                    filters-menu.w-full(:collection="item.link.uid")

            //- size guide
            //- size-guide.absolute.overlay.transition-opacity.bg-white(:open="menu === 'size-guide'", @close="menu = false", v-if="aro", :class="{'opacity-0 delay-400 pointer-events-none': menu !== 'size-guide'}")

        //- (active filters row)
        .relative(:class="{'hidden': searchVisible, 'lg_hidden': !filtersVisible && menu !== 'menu', 'inset-shadow-t': filtersVisible && !menu}")
          filters-active-row-scrollable

        //- clear/apply btns
        div(v-show="menu === 'menu' && filtersActiveCount")
          .lg_hidden.grid.grid-cols-2.border-t.bg-white.pointer-events-auto
            button.border-r(@click="clearFilters")
              btn Clear
            button(@click="closeAllMenus")
              btn Apply

      //- (toggle active filters)
      button.hidden.lg_inline.absolute.z-10.top-0.right-0.h-45.px-15.pointer-events-auto(v-if="filtersActiveCount", @click="filtersVisible = !filtersVisible", :class="{'text-white': !headerTop}") FILTER [{{ ('0' + filtersActiveCount).slice(-2) }}]
      //- (close overlay)
      button.absolute.overlay.pointer-events-auto(v-show="menu", @click="closeAllMenus")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import linkResolver from '@/plugins/prismic/link-resolver'
import Cart from '@/views/cart/Cart'
import kb from 'lodash/kebabCase'
import throttle from 'lodash/throttle'
import FiltersMenu from '@/components/FiltersMenu'
import SearchMenuPanel from '@/components/SearchMenuPanel'
import SearchInputRow from '@/components/SearchInputRow'
import svgIconMenu from '@/components/SVG-AroMenu'
import svgXSmall from '@/components/SVG-X-Small'
import FiltersActiveRowScrollable from '@/components/FiltersActiveRowScrollable'
import Btn from '@/components/Btn'
// skip auto-open if loading saved cart
let cartAutoOpen = localStorage.getItem('checkoutId') === null
export default {
  name: 'AroHeader',
  data () {
    return {
      menu: null,
      menuCursorHoverIndex: -1,
      kb,
      menuHt: 0,
      winH: window.innerHeight,
      searchVisible: false,
      filtersVisible: false,
      headerTop: true,
      previewMenu: this.$route.name === 'aro-index',
      hidePreviewWait: null
      // activeFiltersRowVisible: false
    }
  },
  computed: {
    ...mapState({
      aro: state => state.aro,
      // checkoutUpdating: state => state.shop.checkoutUpdating,
      landingActive: false // state => state.landingActive
      // activeTags: state => state.activeTags
    }),
    ...mapGetters({
      cartTotal: 'shop/cartTotal',
      inventory: 'inventory',
      is: 'is'
    }),
    menuCursorIndex () {
      // if hover active...
      if (this.menuCursorHoverIndex > -1) {
        return this.menuCursorHoverIndex
      }
      // find by path
      const index = this.aro?.menu_primary.findIndex((itm, i) => {
        return this.$route.fullPath.startsWith(linkResolver(itm.link))
      })
      // find "all"
      const allIndex = this.aro?.menu_primary.findIndex(itm => itm.link.uid === 'all')

      return index > -1 ? index
        : allIndex > -1 ? allIndex
          : 0 // default to first item
    },
    activeTags () {
      return this.$store.state.collections.find(doc => doc.uid === this.$route.params.collection)?._tags ?? []
    },
    filtersActiveCount () {
      return this.$store.getters.activeFilters(this.$route).length
    }
  },
  methods: {
    toggle (view) {
      this.setMenuHt()
      this.menu = [view, 'size-guide'].includes(this.menu) ? false : view
    },
    tagGroup (group) {
      return this.activeTags.filter(tg => tg.group === group)
    },
    setMenuHt () {
      // set menu height based on window beacuse ios...
      this.menuHt = window.innerHeight - this.$refs.toprow?.$el.offsetHeight + 'px'
      this.winH = window.innerHeight
    },
    onResize: throttle(function () {
      this.setMenuHt()
    }, 20),
    num (str = '') {
      // 001
      return ('00' + str).slice(-3)
    },
    lockScroll () {
      document.body.style.overflow = 'hidden'
    },
    unlockScroll () {
      document.body.style.overflow = ''
    },
    async onSearchBtnClick () {
      this.searchVisible = !this.searchVisible
      // open? focus input
      if (this.searchVisible) {
        const input = this.$refs.siteSearch
        input.focus()
        // show previous results
        if (input.hasText() && this.menu !== 'search') {
          this.toggle('search')
        }
      } else if (this.menu === 'search') {
        // close results
        this.toggle('search')
      }
    },
    onNewSearch () {
      if (this.menu !== 'search') {
        this.toggle('search')
      }
    },
    closeAllMenus () {
      this.menu = false
      this.searchVisible = false
    },
    clearFilters () {
      return this.$router.replace({ query: {} })
    },

    hidePreviewMenuAfterDelay (delay = 2000) {
      this.hidePreviewWait = setTimeout(() => {
        this.previewMenu = false
      }, delay)
    },
    cancelHidePreviewMenu () {
      clearTimeout(this.hidePreviewWait)
    }
  },
  watch: {
    menu (menu) {
      this.$root.$emit('menu', menu)
      this.searchVisible = menu === 'search'
      return menu ? this.lockScroll() : this.unlockScroll()
    },
    cartTotal (val, old) {
      // open cart on additions
      if (val > old && cartAutoOpen) {
        this.menu = 'cart'
      // close if empty:
      } else if (!val && this.menu === 'cart') {
        this.toggle('cart')
      }
      cartAutoOpen = true
    },
    '$route' (to, from) {
      // keep menu open if using filters...
      if (this.menu !== 'menu' || (to.path !== from.path && to.path === to.fullPath)) {
        this.menu = false
        this.menuCursorHoverIndex = -1
      }
      // exit searches
      this.searchVisible = false
    },
    headerTop (top) {
      this.$root.$emit('aroHeaderTop', top)
    }
  },
  mounted () {
    this.$root.$on('openMenu', this.toggle)
    this.$root.$on('newSearch', this.onNewSearch)
    this.setMenuHt()
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  components: { Cart, FiltersMenu, SearchMenuPanel, svgIconMenu, svgXSmall, SearchInputRow, FiltersActiveRowScrollable, Btn }
}
</script>

<style>
  .aro-header__primary-link.router-link-active span{
    /*@apply underline;*/
  }

  .previewmenu-enter-active,
  .previewmenu-leave-active{
    transition: transform 150ms;
  }
  .previewmenu-leave-to, .previewmenu-enter{
    transform: translateY(-100%);
  }
</style>
