<template lang="pug">
  buttons-row-scrollable.filters-active-row-scrollable(v-if="activeFilters.length", v-bind="$attrs", :items="activeFilters", @itemClick="removeActiveFilter", :closeIcons="true")
</template>

<script>
import ButtonsRowScrollable from '@/components/ButtonsRowScrollable'
export default {
  name: 'FiltersActiveRowScrollable',
  props: ['excludeType'],
  computed: {
    activeFilters () {
      let filters = this.$store.getters.activeFilters(this.$route)
      if (this.excludeType) {
        filters = filters.filter(filter => filter.key !== 'type')
      }
      return filters
    }
  },
  methods: {
    removeActiveFilter ({ key, value }) {
      // clone query
      const query = { ...this.$route.query }
      // get full value of rt key
      const groupValues = query[key]?.split(',')
      // remove filter
      groupValues.splice(groupValues.indexOf(value), 1)
      // update
      query[key] = groupValues.join(',')
      // remove group if no more values
      if (!query[key].length) {
        delete query[key]
      }
      // update route !
      this.$router.replace({ query })
    }
  },
  components: { ButtonsRowScrollable }
}
</script>

<style>
</style>
