<!-- revision: slack note final notes - fz inherit from body -->
<template lang="pug">
  .buttons-row-scrollable.py-7.w-full.overflow-x-scroll.scrollbars-hidden.flex.items-center.pointer-events-auto.uppercase(:class="{'bg-white': !transparentBg}")
    //- row
    .px-7.whitespace-no-wrap
      //- items...
      button.inline-block.mr-7.cursor-pointer.bg-gray-100.hover_bg-black.hover_text-white.pointer-events-auto(v-for="item in items", @click="$emit('itemClick', item)")
        //- rect size
        span.h-30.flex.items-center.uppercase.px-10.leading-none.pb-px
          //- label
          | {{ item.value.replaceAll('-', ' ') }}
          //- (x icon)
          svg-x-small.block.ml-10(v-if="closeIcons", style="width:0.74em;height:0.74em")
</template>

<script>
export default {
  name: 'ButtonsRowScrollable',
  props: ['items', 'closeIcons', 'transparentBg']
}
</script>

<style>
</style>
