<template lang="pug">
  prismic-link(:field="link", :linkResolver="linkResolver")
    slot
</template>

<script>
import linkResolver from '@/plugins/prismic/link-resolver'
export default {
  name: 'AppLink',
  inheritAttrs: false,
  props: ['link'],
  data () {
    return {
      linkResolver
    }
  }
}
</script>

<style scoped>
a.btn-theme-text.router-link-active{
  /*text-decoration: underline*/
}
</style>
