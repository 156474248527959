<template lang="pug">
  modal.search-menu-panel.flex.flex-col.border-t(v-on="$listeners", v-bind="$attrs", :open="open")
    .h-45.px-15.flex.items-center.justify-between.uppercase
      | {{ !results ? 'SEARCHING...' : 'SEARCH RESULTS' }}
      span(v-if="results") {{ results.length }}
    .flex-1.overflow-y-scroll.scrollbars-hidden(v-if="results && results.length")
      //- (results)
      ul.flex.flex-wrap.font-mono.text-mono-11(v-if="results.length")
        li.w-1x2(v-for="product in results")
          router-link.block.pb-ar-2x3.relative(:to="{name: 'aro-product', params: {product: product.handle}}")
            resp-img(:bg="true", :image="img(product)")
          router-link.h-45.flex.items-center.justify-center.pl-15.pr-10(:to="{name: 'aro-product', params: {handle: product.handle}}")
            h6.truncate.w-full {{ product.title }}

</template>

<script>
import RespImg from '@/components/RespImg'
export default {
  name: 'SearchMenuPanel',
  props: ['open'],
  data () {
    return {
      results: null
    }
  },
  methods: {
    async search (term = '') {
      if (term.length) {
        this.results = null
        const resp = await this.$store.dispatch('shop/getProducts', { query: term }).catch(e => console.error(e))
        this.results = resp?.edges?.map(edge => edge.node)
      }
    },
    img (doc) {
      return doc?.images?.edges?.map(e => e.node)[0]
    }
  },
  mounted () {
    this.$root.$on('newSearch', this.search)
  },
  components: { RespImg }
}
</script>

<style>
/*.search-menu-panel{
  & input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
  }
}*/
</style>
