<template lang="pug">
  <svg width="12.5" height="12.5" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M13.195 0.494999L0.495 13.195" stroke="currentColor" stroke-width="1.4"/>
    <path d="M0.496 0.494L13.196 13.194" stroke="currentColor" stroke-width="1.4"/>
  </svg>
</template>

<script>
export default {
  name: 'Name'
}
</script>

<style>
</style>
