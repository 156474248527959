<template lang="pug">
  article.collection.relative.bg-gray-100(:key="collection", :class="{'is-scrolling': isScrolling, 'pt-45 lg_pt-0': isCollectionView}")

    //- (breadcrumb)
    nav.hidden.lg_block.sticky.z-20.top-0.left-0(v-if="isCollectionView")
      breadcrumb(:crumbs="breadcrumbSmall", :isWhite="isBreadcrumbSticky")

    template(v-if="doc")
      header
        //- (seo title)
        h1.sr-only(v-if="isCollectionView") {{ $prismic.asText(doc.data.title) }}

        //- breadcrumb large
        nav.h-40.lg_h-60.flex.items-center.pb-3.lg_pb-4.overflow-hidden
          //- scrollable row
          .w-full.overflow-x-scroll.scrollbars-hidden
            //- row
            .px-15.whitespace-no-wrap.text-25.lg_text-40.font-light
              //- this collection
              router-link.inline-block.mr-20.uppercase(:to="{ name: 'aro-collection', params: { collection: doc.uid }}") {{ $prismic.asText(doc.data.title) }}<sup>{{ formatInventory(doc._inventory) }}</sup>
              //- filters...
              router-link.inline-block.mr-20.uppercase(v-for="crumb in breadcrumLargeFilters", :to="`/collections/all?${crumb.key}=${crumb.value}`") {{ crumb.title }}<sup>{{ formatInventory(crumb.inventory) }}</sup>

        //- laptop/index breadcrumb, sticky
        //- breadcrumb-sticky.hidden.lg_block.z-20(:crumbs="breadcrumbs", @click="onBreadcrumbClick")

        //- (mobile breadcrumbs)
        //- header.bg-white.h-45.border-b.flex.items-center.lg_hidden.border-t
          breadcrumb-text(:back="isCollectionView", :crumbs="breadcrumbs", :countGray="true", @back="onBreadcrumbBack", @click="onBreadcrumbClick")

        //- (mobile filters)
        //- filters-active-row-scrollable.sticky.z-10.top-45.left-0.w-full.lg_hidden(v-if="isCollectionView && !is('lg')", :transparentBg="true", :excludeType="true")

        //- description
        prismic-rich-text.hidden.lg_block.py-10.px-17.text-25.uppercase.-mb-px(:field="doc.data.description", v-if="hasDescription")
          //- NOTE: description could also be from a unique prismic doc so they don't have to make collections for each brand... (eg "tag_meta" which is searched if 1 designer is in route query...)

      //- slices (products)
      slice-body.flex.flex-wrap.bg-white(:slices="slicesFiltered", :key="$route.path")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatInventory } from '@/store'
import kb from 'lodash/kebabCase'
import throttle from 'lodash/throttle'
import svgChevronLeft from '@/components/SVG-ChevronLeft'
import FiltersActiveRowScrollable from '@/components/FiltersActiveRowScrollable'
let afterScroll
// let lastRt
export default {
  name: 'Collection',
  props: ['collection', 'isSlice'],
  data () {
    return {
      doc: null, // 404 = undefined
      isScrolling: false,
      isBreadcrumbSticky: false
    }
  },
  computed: {
    ...mapState(['canHover']),
    ...mapGetters(['is']),
    isCollectionView () {
      return this.$route.name === 'aro-collection'
    },
    filtersActive () {
      return Object.keys(this.$route.query).length
    },
    slices () {
      let slices = this.doc?.data.body || []
      // only slices with products...
      slices = slices.filter(slice => slice.primary.shopify_product)
      // add tags ?
      if (this.filtersActive) {
        // add product tags to slice
        slices = slices.map(slice => ({
          _tags: slice.primary.shopify_product.tags
            .split(', ')
            .map(tag => tag.includes(':') ? kb(tag) : 'misc-' + kb(tag)), // colour-white, misc-dresses (unprefixed)
          ...slice
        }))
      }
      return slices
    },
    slicesFiltered () {
      // clone base
      let slices = this.slices.slice()

      // filter down by route query values!
      if (this.filtersActive) {
        const keys = Object.keys(this.$route.query) // [type, colour] IN ORDER OF ADDITION !!
        // for each group...
        for (var i = 0; i < keys.length; i++) {
          if (slices.length) {
            const key = keys[i]
            // collect values into an array
            let active = this.$route.query[key]?.split(',') || []
            active = active.map(val => key + '-' + val) // 'type-dresses'
            // reduce slices by values
            slices = slices.filter(slice => slice._tags.find(tag => active.includes(tag)))
          }
        }
      }

      return slices
    },
    hasDescription () {
      return this.doc?.data.description && this.$prismic.asText(this.doc.data.description).length
    },
    breadcrumbSmall () {
      return [{
        title: this.doc ? this.$prismic.asText(this.doc.data.title)
          : this.doc === undefined ? 'Not Found'
            : 'Loading...',
        path: this.$route.path
      }]
    },
    breadcrumLargeFilters () {
      const crumbs = []

      // loop through $route query keys...
      Object.keys(this.$route.query).forEach(key => {
        // split all values into { key, value } objects
        const keyVals = this.$route.query[key].split(',')
          .filter(value => value.length) // should have a value
          .map(value => ({ key, value }))

        // loop through values...
        keyVals.forEach(({ key, value }) => {
          // get inventory from slice meta
          const inventory = this.slices.filter(slice => slice._tags.find(tag => tag === `${key}-${value}`)).length
          // add
          crumbs.push({
            key,
            value,
            title: value.replaceAll('-', ' '),
            inventory
          })
        })
      })

      return crumbs
    }
  },
  watch: {
    slicesFiltered () {
      this.updateActiveFilters()
    }
  },
  methods: {
    async get () {
      this.doc = await this.$store.dispatch('getCollection', this.collection)
    },
    onScroll: throttle(function () {
      // apply scrolling class to disable product thumb hover animations while scrolling
      if (!this.isScrolling) {
        this.isScrolling = true
      }
      clearTimeout(afterScroll)
      afterScroll = setTimeout(() => { this.isScrolling = false }, 150)
    }, 100),
    updateActiveFilters () {
      // collect all tags
      let filters = this.slicesFiltered.reduce((acc, slice) => slice._tags && acc.concat(slice._tags), [])
      // de-dupe
      filters = [...new Set(filters)]
      // store
      this.$root.$emit('activeFiltersUpdated', filters)
    },
    onBreadcrumbBack () {
      // return lastRt?.name ? this.$router.go(-1) : this.$router.push('/')
      return this.$router.push('/')
    },
    onBreadcrumbClick () {
      return this.$root.$emit('collectionsMenuOverlay', true)
    },
    formatInventory
  },
  // lifecycle
  // beforeRouterEnter (to, from, next) {
  //   lastRt = from
  //   next()
  // },
  created () {
    this.get()
    this.updateActiveFilters()
  },
  mounted () {
    if (this.canHover) window.addEventListener('scroll', this.onScroll)
    this.$root.$on('aroHeaderTop', (top) => { this.isBreadcrumbSticky = !top })
  },
  destoryed () {
    this.$store.commit('setActiveTags', [])
    window.removeEventListener('scroll', this.onScroll)
  },
  metaInfo () {
    return this.doc && !this.isSlice && {
      title: this.$prismic.asText(this.doc.data.title)
    }
  },
  components: { svgChevronLeft, FiltersActiveRowScrollable }
}
</script>

<style>
</style>
