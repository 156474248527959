<template lang="pug">
  details.details-dropdown.border-tff.border-bff.-mb-pxff(:class="{'details-dropdown--icons-lrg': iconsLarge}")
    summary.focus_outline-none.select-none
      //- nested for safari :)
      .min-h-35.px-17.flex.items-center.justify-between.focus_outline-none.cursor-pointer.uppercase(:class="{'bg-gray-100': summaryGray}")
        slot(name="summary")
    slot
</template>

<script>
export default {
  name: 'DetailsDropdown',
  props: ['iconsLarge', 'summaryGray']
}
</script>

<style lang="postcss">
details {
  & summary > div::after{
    content: '';
    display:inline-block;
    width:9px;
    height:9px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(~@/assets/aro-icon-plus.svg);
  }
  &[open] summary > div::after{
    background-image: url(~@/assets/aro-icon-minus.svg);
  }

  &.details-dropdown--icons-lrg{
    & summary > div::after{
      background-image: url(~@/assets/aro-icon-plus-medium.svg);
      width:10px;
      height:10px;
    }
    &[open] summary > div::after{
      background-image: url(~@/assets/aro-icon-minus-medium.svg);
    }
  }
}

/* @media (hover:hover) {
  details {
    & summary > div::after{
      opacity:0;
    }
    &:hover summary > div::after{
      opacity:1;
    }
  }
} */
</style>
