<template lang="pug">
  .products-results.flex.flex-wrap
    product-thumb.w-1x2.lg_w-1x4(v-for="doc in results", :doc="doc", :key="doc.id", :images="images", :price="doc.priceRange.minVariantPrice.amount")
</template>

<script>
export default {
  name: 'ProductsResults',
  props: ['results'],
  methods: {
    images (doc) {
      return doc?.images?.edges?.map(e => e.node) || []
    }
  }
}
</script>

<style>
</style>
