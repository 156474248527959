<template lang="pug">
  .cart-item.flex.mx-15.py-15.uppercase(:class="{'opacity-50 pointer-events-none': status}")
    //- image
    figure.w-100.h-140.relative
      resp-img(:bg="true", :image="item.variant.image", :alt="item.title + ' ' + item.variant.title")
    //- details
    .flex-1.pl-17.flex.flex-col.justify-between
      header.flex.flex-col
        //- title
        | {{ vendor }}
        h6 {{ title }}
        //- code
        | {{ item.variant.sku }}
        variant-options-list.mt-lh(:variant="item.variant")
          //- qty / editor
          //- li.flex
            label.mr-8 Qty:
            input.input-qty.hover_underline.focus_underline.focus_outline-none(ref="input", v-model="qty", type="number", min="0", size="2", @click="e => e.target.select()", :disabled="status !== null", @keydown.enter="e => e.target.blur()", @blur="updateQuantity")
            button.sr-only(@click="updateQuantity") Update Quantity
      //- actions
      fieldset.mt-lh.flex.w-full.leading-none
        //- button.mr-15.btn-theme-text(@click="$refs.input.focus()") Edit
        button.btn-theme-text(@click="removeItem", :disabled="status !== null") Remove
        //- price / sold-out
        .flex-1.text-right
          template(v-if="!item.variant.availableForSale") Sold Out
          template(v-else) {{ price(item.quantity * item.variant.priceV2.amount, false) }}

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import VariantOptionsList from '@/components/product/VariantOptionsList'
export default {
  name: 'CartItem',
  props: ['item'],
  data () {
    return {
      status: null,
      qty: 0
    }
  },
  computed: {
    ...mapState({ checkout: state => state.shop.checkout }),
    ...mapGetters({ price: 'shop/price' }),
    vendor () {
      return this.item.customAttributes.find(attribute => attribute.key === 'vendor')?.value
    },
    title () {
      return this.vendor ? this.item.title.replace(this.vendor + ' ', '') : this.item.title
    },
    lineItems () {
      return !this.checkout ? []
        : this.checkout.lineItems.map(itm => ({ variantId: itm.variant.id, quantity: itm.quantity }))
    },
    lineItemIndex () {
      // index position of line item in checkout.lineItems
      return this.lineItems.findIndex(itm => itm.variantId === this.item.variant.id)
    }
  },
  methods: {
    async removeItem () {
      const i = this.lineItemIndex
      if (i > -1) {
        this.status = 'Removing...'
        const newItems = [...this.lineItems]
        newItems.splice(i, 1)
        await this.$store.dispatch('shop/updateCheckoutItems', newItems)
        this.status = null
      }
    },
    async updateQuantity () {
      const qty = Math.max(0, Number(this.qty))
      if (qty === this.item.quantity) return
      // remove
      if (qty === 0) return this.removeItem()
      // update
      const i = this.lineItemIndex
      if (i > -1) {
        this.status = 'Updating...'
        const newItems = [...this.lineItems]
        newItems[i].quantity = qty
        await this.$store.dispatch('shop/updateCheckoutItems', newItems)
        this.status = null
      }
    }
  },
  watch: {
    item () {
      this.qty = this.item.quantity
    }
  },
  created () {
    this.qty = this.item.quantity
  },
  components: { VariantOptionsList }
}
</script>

<style>
input.input-qty::-webkit-outer-spin-button,
input.input-qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number].input-qty {
  appearance:none;
  /* Firefox */
  -moz-appearance: textfield;
}
</style>
