import { render, staticRenderFns } from "./SVG-X-Small.vue?vue&type=template&id=0105776e&lang=pug&"
import script from "./SVG-X-Small.vue?vue&type=script&lang=js&"
export * from "./SVG-X-Small.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports