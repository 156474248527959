<template lang="pug">
  .filters-menu.select-none.flex.flex-col
    .flex-1.pb-45
      //- groups...
      details-dropdown.border-bff(v-for="(group, i) in groups", v-if="itemsOf(group)", :open="!i", :class="{'border-transparentff': !i}", :iconsLarge="true", :summaryGray="i % 2 !== 0")
        //- summary
        h6.h-45.flex.items-center(slot="summary") {{ group }}
        //- (body)
        ul
          //- items...
          li(v-for="(item, ii) in itemsOf(group)", :class="{'text-gray-300 pointer-events-none': filterDisabled(group, item.name)}")
            label.h-35.px-15.w-full.flex.items-center.justify-between.cursor-pointer(:class="{'bg-gray-100': i % 2 === ii % 2, 'border-b border-white': ii === itemsOf(group).length - 1, 'border-gray-100': i % 2 !== ii % 2}")
              .flex.items-center
                //- checkbox
                input.border.rounded-full.mr-15.focus_outline-none(type="checkbox", @change="toggleFilter(group, item.name)", :checked="filterIsActive(group, item.name)", :disabled="filterDisabled(group, item.name)")
                | {{ item.name }}
              //- count ("01")
              div.tabular-nums {{ formatInventory(item.inventory) }}
</template>

<script>
import kb from 'lodash/kebabCase'
import throttle from 'lodash/throttle'
import DetailsDropdown from '@/components/DetailsDropdown'
import { formatInventory } from '@/store'
export default {
  name: 'FiltersMenu',
  props: ['collection'],
  data () {
    return {
      groups: ['type', 'designer', 'theme', 'colour', 'material', 'size'],
      activeTags: null
    }
  },
  computed: {
    isActiveMenu () {
      return this.$route.name === 'aro-collection' && this.$route.params.collection === this.collection
    },
    tags () {
      let tags = this.$store.state.collections.find(coll => coll.uid === this.collection)?._tags
      tags = tags || []
      // sort alpha
      return tags.sort((a, b) => a.name < b.name ? -1 : 0)
    }
  },
  methods: {
    kb,
    formatInventory,
    itemsOf (name) {
      return this.tags.filter(tag => tag.group === name)
    },
    toggleFilter: throttle(function (group, value) {
      value = kb(value)

      // ...if not active collection > push!
      if (!this.isActiveMenu) {
        const query = {}
        query[group] = kb(value) // {type: 'dresses'}
        return this.$router.push({ name: 'aro-collection', params: { collection: this.collection }, query })
      }

      // ...else toggle
      // clone rt query
      const query = { ...this.$route.query }
      // "active" value for that group - 'coats,dresses,...'
      let active = query[group]?.split(',') || []

      // add/remove
      if (active.includes(value)) {
        // ...remove from active
        active.splice(active.indexOf(value), 1)
      // ...add?
      } else {
        // // ...type can only have 1 active
        // if (group === 'type') {
        //   active = [value] // overwrite
        // // ...else add
        // } else {
        //   active.push(value)
        // }
        active.push(value)
      }

      // format back to string
      active = active.join(',')
      query[group] = active
      // remove key if empty values
      if (!query[group].length) {
        delete query[group]
      }
      // update !
      this.$router.replace({ query })
    }, 100),

    filterIsActive (group, value) {
      return this.isActiveMenu && this.$route.query[group]?.split(',').includes(kb(value))
    },
    filterDisabled (group, name) {
      if (this.activeTags) {
        // for 'type' filter, limit 1 (and keep any active type filter "enabled" for deselection)
        // const isTypeGroupAndMaxReached = group === 'type' && this.$route.query.type?.split(',').length && this.$route.query.type !== kb(name)
        // if (isTypeGroupAndMaxReached) {
        //   return true
        // }
        // otherwise...
        // ...filter is in first query group, which allows multi-select (except type above)
        const filterIsInFirstQueryGroup = Object.keys(this.$route.query)[0] === group
        // ...filter is not currently active
        const filterIsInactive = !this.activeTags.includes(`${group}-${kb(name)}`)
        //
        return !filterIsInFirstQueryGroup && filterIsInactive
      }
      return false
    },
    onActiveFiltersUpdated (tags) {
      this.activeTags = this.isActiveMenu && tags.length ? tags
        : null
    }
  },
  mounted () {
    this.$root.$on('activeFiltersUpdated', this.onActiveFiltersUpdated)
  },
  components: { DetailsDropdown }
}
</script>

<style lang="postcss">
.filters-menu{
  & input[type=checkbox] {
    -webkit-appearance:none;
    width:10px;
    height:10px;
    &:checked{
      background:currentColor;
      border-color: currentColor;
    }
  }
}
</style>
