<template lang="pug">
  .z-50.w-full.fixed.bottom-0.md_flex.md_justify-center.font-mono(v-if="accepted === null")
    .text-12.md_text-13.bg-black.h-45.text-white.w-full.lg_w-1x3.flex.justify-between.items-center.px-10.md_px-17
      p THIS WEBSITE USES COOKIES. <prismic-link v-if="policyLink" :field="policyLink" :linkResolver="linkResolver" class="border-b ml-2 leading-underline inline-block">LEARN MORE</prismic-link>
      button(@click="acceptCookies") ACCEPT
</template>

<script>
import { bootstrap } from 'vue-gtag'
import linkResolver from '@/plugins/prismic/link-resolver'
export default {
  name: 'CookieBanner',
  data () {
    return {
      accepted: undefined,
      linkResolver
    }
  },
  computed: {
    policyLink () {
      return this.$store.state.aro?.cookie_policy
    }
  },
  methods: {
    async acceptCookies () {
      await this.enableAnalytics(true)
      // hide banner regardless of whether initialized
      this.accepted = new Date()
      localStorage.setItem('cookies_accepted', this.accepted)
    },

    async enableAnalytics (recordPageview) {
      try {
        const resp = await bootstrap()

        // error/blocked
        if (resp.type === 'error') {
          return false
        }

        if (recordPageview) {
          this.$gtag.pageview({ page_path: this.$route.path, page_title: document.title })
        }
      } catch (e) {
        console.error(e)
      }
    },

    async init () {
      await this.$store.dispatch('getAro')

      this.accepted = localStorage.getItem('cookies_accepted')

      if (this.accepted) {
        // check if policy hasn't been updated...
        const published = this.policyLink.last_publication_date
        if (published && new Date(published) > new Date(this.accepted)) {
          // ...invalidate
          localStorage.removeItem('cookies_accepted')
        } else {
          // ...otherwise enable!
          return this.enableAnalytics()
        }
      }

      // revert to
      this.accepted = null
    }
  },
  created () {
    this.init()
  }
}
</script>
