<template lang="pug">
  //- overflow hidden on each item because line-height creates inner y scroll for some reason
  links-bar.slice-collections-bar.bg-black.text-white(v-if="slice.items.length", @visible="fetchCollections")
    //- lead
    .inline-block.mr-10.overflow-hidden.pt-3.pb-3.lg_pb-4(v-if="slice.primary.lead.length") {{ slice.primary.lead }}
    //- items...
    router-link.inline-block.px-8.py-2.hover_bg-white.hover_text-black.group.overflow-hidden(v-for="(item, i) in slice.items", :to="{name: 'aro-collection', params: {collection: item.collection.uid}}")
      .flex.items-center
        //- title
        span.block.transform.-translate-y-1.lg_-translate-y-3 {{ $prismic.asText(item.collection.data.title) }}
        //- comma
        span.group-hover_hidden(v-if="i < slice.items.length - 1 && slice.items.length > 1") ,
        //- images...
        img.hidden.group-hover_inline-block.ml-8(v-for="src in images(item.collection.id)", :src="src")
    //- add right margin bc x-scroll :)
    span.inline-block.pr-15
</template>

<script>
import { mapState } from 'vuex'
import LinksBar from '@/components/LinksBar'
const dpx = window.devicePixelRatio || 1
export default {
  name: 'SliceCollectionsBar',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) }
  },
  data () {
    return {
      collections: []
    }
  },
  computed: {
    ...mapState(['canHover'])
  },
  methods: {
    async fetchCollections () {
      if (!this.collections.length && this.canHover) {
        // collect ids into single array
        const ids = this.slice.items.filter(item => item.collection?.id).map(item => item.collection.id)
        // fetch !
        this.collections = (await this.$prismic.client.getByIDs(ids)).results
      }
    },

    images (collectionId) {
      /*
       * return array of image sources from first X products of a collection
      */
      const collection = this.collections.find(coll => coll.id === collectionId)
      let images = []
      if (collection) {
        images = collection.data.body
          .filter(slice => slice.primary?.shopify_product?.image?.src) // if has image
          .slice(0, 7) //
          .map(slice => {
            // resize image via shopify...
            let src = slice.primary.shopify_product.image.src
            const ext = src.slice(src.lastIndexOf('.'))
            const h = 40 * dpx
            src = src.replace(ext, `_x${h}` + ext)
            return src
          })
      }
      return images
    }
  },
  components: { LinksBar }
}
</script>

<style>
.slice-collections-bar img{
  height:0.87em;
  width:calc(0.87em * 2/3);
}
.slice-collections-bar a{
  /*line-height: 0.9;*/
}
</style>
