<template lang="pug">
  router-link.product-thumb.block.btn-theme-white.group.flex.flex-col.font-mono.text-mono-12.uppercase(v-if="doc", :to="rt", :class="{'product-thumb--can-hover': canHover}", @mouseenter.native="loadHoverImage = true")
    //- title
    header.h-45.px-17.flex.items-center.pt-3
      //- (reader title)
      h6.sr-only {{ doc.title }}

      //- (visible title)
      .flex.justify-between.w-full.group-hover_hidden
        .flex-1.truncate
          .inline {{doc.vendor}}
        .pl-15.text-right.hidden.lg_block(v-if="size") {{ size }}
        .pl-15.text-right(style="min-width:3em") {{ money(price) }}
      //- (title on hover)
      .hidden.group-hover_flex.w-full.justify-between
        .truncate.uppercase {{ title }}
        .hidden.lg_block(v-if="season") {{ season }}

    //- image (ordered first)
    figure.order-first.relative.pb-ar-2x3.bg-gray-100.overflow-hidden
      //- image
      resp-img.absolute.overlay(:bg="true", :image="images[0]", :alt="`${doc.title} - Aro Archive`")
      //- (hover image slides over)
      template(v-if="canHover && images[1] && loadHoverImage && !isSoldOut")
        resp-img.absolute.top-0.w-full.h-full.left-100.transform.transition-transform.duration-250.group-hover_delay-200(:bg="true", :image="images[1]", :alt="`${doc.title} - Aro Archive`", :anim="false", @load="hoverImageLoaded = true", :lazy="true", :class="{'group-hover_-translate-x-full': hoverImageLoaded}")
      //- ("sold out" bar)
      .absolute.overlay.flex.items-center.justify-center(v-if="isSoldOut")
        span.w-3x4.md_w-2x3.lg_w-1x2.h-45.flex.items-center.justify-center.bg-black.text-white Sold

    //- (debug tags)
    ul.list-none(v-if="$route.hash === '#debug'")
      li.inline-block(v-for="tag in doc.tags.split(',')", :class="{'bg-black text-white': !tag.includes(':')}") {{ tag }},
</template>

<script>
import { mapState, mapGetters } from 'vuex'
const seasonRegex = /[A-Z]{2}\d*.?$/
export default {
  name: 'ProductThumb',
  props: {
    doc: Object,
    images: Array,
    price: String,
    hover: Boolean,
    hideSeason: Boolean
  },
  data () {
    return {
      loadHover: false,
      loadHoverImage: false,
      hoverImageLoaded: false
    }
  },
  computed: {
    ...mapState(['canHover']),
    ...mapGetters({ money: 'shop/price' }),
    title () {
      // remove vendor from title...
      return this.doc.title
        .replace(this.doc.vendor + '', '')
        .replace(this.season, '')
    },
    season () {
      const season = this.doc?.title?.match(seasonRegex)
      return season && season[0]
    },
    size () {
      let size
      // first: try size from tag...
      let tags = this.doc?.tags
      if (tags) {
        tags = typeof tags === 'string' ? tags.split(',') : tags
        size = tags.find(tag => tag.toLowerCase().startsWith('size:'))
        if (size) {
          return size.split(':')[1]
        }
      }
      // fallback to size from variants (if not-multiple)
      if (this.doc?.variants?.length === 1) {
        // get size from variant...
        const variant = this.doc.variants[0] || this.doc.variants?.edges[0]?.node
        // shopify data ?
        size = variant?.selectedOptions?.find(opt => opt.name.toLowerCase() === 'size')?.value ||
          // prismic data ?
          (variant?.option1 !== 'NA' && variant?.option1)
        return size && size.split(' ')[0]
      }
      return size
    },
    rt () {
      const col = this.$route.params.collection
      return col ? { name: 'aro-collection-product', params: { collection: col, product: this.doc.handle } }
        : { name: 'aro-product', params: { product: this.doc.handle } }
    },
    isSoldOut () {
      if (this.doc?.availableForSale !== undefined) {
        return !this.doc.availableForSale
      } else if (this.doc?.variants?.length) {
        return !this.doc.variants.find(variant => {
          const hasInventory = variant.inventory_quantity > 0
          const limitless = variant.inventory_quantity === 0 && variant.inventory_policy !== 'deny'
          return hasInventory || limitless
        })
      }
      return false
    }
  }
}
</script>

<style>
/* disable hover animations while scrolling */
.is-scrolling .product-thumb.product-thumb--can-hover{
  pointer-events: none;
}

@media (hover:none) {
  /*.product-thumb .opacity-0.group-hover_opacity-100{
    opacity:100;
  }*/
}
</style>
