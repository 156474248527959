<template lang="pug">
  observer.slice-hero-vimeo.h-screen.relative.bg-black.flex.justify-center.py-15.text-white(:threshold="0.01", @visible="visible = true", @hidden="visible = false")
    //- (archive logo)
    //- logo-archive.sticky.z-10.top-15(v-if="slice.primary.link_to.type === 'archive'")
    //- (aro logo)
    //- logo.sticky.z-10.top-15(v-else-if="slice.primary.link_to.type === 'site'")

    //- media layer
    .absolute.overlay.overflow-hidden
      //- image
      resp-img(:bg="true", :image="slice.primary.vimeo_poster")
      //- (vimeo)
      vimeo-player(v-if="vimeoUrl", ref="vimeo", :vimeoURL="vimeoUrl", bgSize="cover", :visible="visible", :clip="[slice.primary.vimeo_clip_start, slice.primary.vimeo_clip_end]")

    //- link overlay area
    router-link.absolute.z-10.overlay.block.flex.items-center.justify-center.text-center(:to="linkResolver(slice.primary.link_to)")
      //- heading (loads hidden on Home)
      h2.slice-hero-vimeo__heading.text-30.lg_text-60.font-light.transition.duration-500.uppercase {{ $prismic.asText(slice.primary.heading) }}
</template>

<script>
import linkResolver from '@/plugins/prismic/link-resolver'
export default {
  name: 'SliceHeroVimeo',
  props: {
    slice: Object
  },
  data () {
    return {
      visible: true,
      linkResolver
    }
  },
  computed: {
    vimeoUrl () {
      return this.slice?.primary?.vimeo_url?.url
    }
  }
}
</script>

<style>
</style>
