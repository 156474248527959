<template lang="pug">
  ul.aro-header-menu-primary.flex.h-45.relative.flex.bg-gray-100.py-2.inset-shadow-t(v-if="aro")
    //- primary menu items...
    li.w-1x3.flex(v-for="(item, i) in aro.menu_primary", @mouseenter="$emit('hover', i)")
      app-link.aro-header__primary-link.flex.items-center.justify-center.w-full(:link="item.link", :class="{'border-l border-white': i}")
        span.border-b.border-transparent(:class="{'border-current': active === i}", style="line-height:1.25") {{ item.link_text }}
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AroHeaderMenuPrimary',
  props: ['active'],
  computed: {
    ...mapState(['aro'])
  },
  mounted () {
    this.$emit('mounted')
  }
}
</script>

<style>
</style>
