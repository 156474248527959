<template lang="pug">
  section.slice-body
    component(v-for="(slice, i) in slices", v-if="has(slice)", :is="'slice--' + slice.slice_type", :slice="slice", :key="`slice${i}`")
</template>

<script>
import SliceProduct from '@/slices/SliceProduct'
import SliceHeroVimeo from '@/slices/SliceHeroVimeo'
import SliceCollectionSynopsis from '@/slices/SliceCollectionSynopsis'
import SliceProductsCollection from '@/slices/SliceProductsCollection'
import SliceText from '@/slices/SliceText' // page text
import SliceNewsletterSignUp from '@/slices/SliceNewsletterSignUp'
import SliceTickerText from '@/slices/SliceTickerText'
import SliceTiles from '@/slices/SliceTiles'
import SliceHeadingIndex from '@/slices/SliceHeadingIndex'
import SliceTileCarousel from '@/slices/SliceTileCarousel'
import SliceCollectionsBar from '@/slices/SliceCollectionsBar'
import SlicePopularTagsBar from '@/slices/SlicePopularTagsBar'
export default {
  name: 'SliceBody',
  props: {
    slices: { type: Array, default: () => ([]) }
  },
  methods: {
    has (slice) {
      return this.$options.components['slice--' + slice.slice_type]
    }
  },
  components: {
    'slice--product': SliceProduct,
    'slice--hero_vimeo': SliceHeroVimeo,
    'slice--collection_synopsis': SliceCollectionSynopsis,
    'slice--products_collection': SliceProductsCollection,
    'slice--text': SliceText,
    'slice--newsletter_sign-up': SliceNewsletterSignUp,
    'slice--ticker_text': SliceTickerText,
    'slice--tiles': SliceTiles,
    'slice--tile_carousel': SliceTileCarousel,
    'slice--heading_index': SliceHeadingIndex,
    'slice--collections_bar': SliceCollectionsBar,
    'slice--popular_tags_bar': SlicePopularTagsBar
  }
}
</script>

<style>
</style>
