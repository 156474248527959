<template lang="pug">
  //- overflow hidden on each item because line-height creates inner y scroll for some reason
  links-bar.slice-collections-bar.bg-gray-100.text-black.pb-2.lg_pb-4(v-if="slice.items.length")
    //- lead
    .inline-block.mr-15.py-3.lg_py-4(v-if="slice.primary.lead.length") {{ slice.primary.lead }}
    //- items...
    router-link.inline-block.mr-7.px-8.pt-px.pb-3.lg_pt-0.lg_pb-5.bg-white.hover_bg-black.hover_text-white(v-for="item in slice.items", :to="{name: 'aro-collection', params: {collection: item.collection.uid}, query: itmQuery(item) }") {{ item.shopify_tag.split(':').pop() }}
</template>

<script>
import LinksBar from '@/components/LinksBar'
import kb from 'lodash/kebabCase'
export default {
  name: 'SlicePopularTagsBar',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) }
  },
  methods: {
    itmQuery (item) {
      const query = {}
      let tag = item.shopify_tag
      if (tag) {
        tag = tag.split(':').length > 1 ? tag.split(':') : ['type', tag]
        query[kb(tag[0])] = kb(tag[1])
      }
      return query
    }
  },
  components: { LinksBar }
}
</script>

<style>
</style>
