<template lang="pug">
  //- search
  article.archive-layout.fixed.overlay.transition-background.duration-200.text-white.bg-gray-900(:style="{background: doc &&  doc.bg_color}")
    template(v-if="doc")
      //- header
      header.fixed.z-20.top-0.right-0.flex.justify-center.w-full
        .w-full.lg_w-1x3.bg-black.text-white
          app-logo-bar
            //- back btn
            button.h-45.w-45.flex.items-center.justify-center(slot="left", @click="close", aria-label="Close About")
              //- span.icon-chevron-left
              svg-chevron-left
            //- logo
            router-link(slot="center", :to="{name: 'archive'}")
              logo-archive.cursor-pointer
            //- about btn
            button.h-45.w-45.flex.items-center.justify-center.group(slot="right", @click="about = !about", aria-label="Visually toggle About/Contact")
              //- span.icon-menu.icon-menu--white(:class="{'icon-menu--white--close': about}")
              //- svg-aro-menu(:open="about")
              img(src="~@/assets/icon-info-white.svg", style="width:2.2rem;height:2.2rem", alt="Info")

      //-  vimeo
      main-el
        h1.sr-only {{ $prismic.asText(doc.title) }}

        figure.absolute.overlay.flex.lg_py-12vh.lg_px-12vw
          .relative.w-full(v-if="doc.vimeo_url.url")
            //- player
            vimeo-player(ref="vimeo", :vimeoURL="doc.vimeo_url.url", bgSize="contain", :clip="[doc.vimeo_clip_start, doc.vimeo_clip_end]", @hasimagery="loading = false", @playing="onVimeoPlaying")
            //- play btn
            button.absolute.overlay.flex.justify-center.items-center.text-white.group(@click="$refs.vimeo.playBtnClick()", :disabled="loading")
              .rounded.py-8.px-15.lg_py-20.lg_px-50.transition.duration-150.bg-black-a50.group-hover_bg-black-a80.transition.duration-200(:class="{'opacity-0': loading || playing}")
                //- play icon
                <svg class="w-15 h-15 lg_w-40 lg_h-40 block" viewBox="0 0 71 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 92V0L71 46L0 92Z" fill="white" />
                </svg>

        //- (info overlay - as black column)
        aside.fixed.z-10.bottom-0.right-0.h-full.w-full.flex.justify-center.pointer-events-none(v-if="hasInfoPanel", v-show="!about")
          //- panel body
          .w-full.lg_w-1x3.text-white.flex.flex-col-reverse.pt-45(:class="{'shadow': info}")
            //- btn (ordered last)
            button.sticky.bottom-0.left-0.w-full.h-45.flex.items-center.justify-center.pointer-events-auto.uppercase.text-14(@click="info = !info", :class="{'bg-black': info}") {{ info ? 'CLOSE' : infoPanelButtonLabel }}

            //- (body expands)
            .flex-1.overflow-y-scroll.scrollbars-hidden.bg-black.border-t.border-b.border-gray-800.px-15.pointer-events-auto(v-show="info")
              prismic-rich-text.children-mt-lh.py-15.font-mono.text-mono-12.tracking-50(:field="doc.info_panel")

      //- (global archive about / contact)
      footer.absolute.z-10.overlay.overflow-y-scroll.scrollbars-hidden.px-15.text-14(v-if="archive", ref="about", :class="{'opacity-0 pointer-events-none': !about}", style="backdrop-filter:blur(20px); background: rgba(0,0,0,0.15)", @click="about = false")
        //- about
        .min-h-screen.flex.items-center.justify-center.py-50.lg_py-150.pointer-events-none(v-if="hasText(archive.about)")
          h2.sr-only About
          .w-full.max-w-2xl.mx-auto.py-50(@click.stop, :class="{'pointer-events-auto': about}")
            prismic-rich-text.children-mt-lh(:field="archive.about")
        //- services
        .min-h-screen.flex.items-center.justify-center.py-50.lg_py-150.-mt-100.lg_-mt-200.pointer-events-none(v-if="hasText(archive.services)")
          h3.sr-only Services
          .w-full.max-w-2xl.mx-auto.py-50(@click.stop, :class="{'pointer-events-auto': about}")
            prismic-rich-text.children-mt-lh(:field="archive.services")
        //- contact
        .min-h-screen.flex.items-center.justify-center.py-50.lg_py-150.-mt-100.lg_-mt-200.pointer-events-none(v-if="hasText(archive.contact)")
          h3.sr-only Contact
          .w-full.max-w-2xl.mx-auto.children-mt-lh.py-50(@click.stop, :class="{'pointer-events-auto': about}")
            prismic-rich-text.children-mt-lh(:field="archive.contact")
            //- newsletter form
            //- form.h-45.border.border-current.w-full.md_max-w-md.mr-auto.leading-none.text-left.flex.flex-col.justify-center(@submit.prevent="subscribe")
              .w-full(:class="{'h-0 overflow-hidden': msg}")
                label.sr-only(for="archive-newsletter-email-input") Sign up for news
                input.py-12.px-17.w-full.bg-transparent.focus_outline-none.leading-none(v-model="email", required, type="email", id="archive-newsletter-email-input", name="email", placeholder="Sign up for news")
                button.sr-only(type="submit") Submit
              //- msg
              p.py-12.px-17.cursor-pointer.leading-none.w-full(v-show="msg", @click="msg = null") {{ msg }}
</template>

<script>
import { mapState } from 'vuex'
import throttle from 'lodash/throttle'
// import svgAroMenu from '@/components/SVG-AroMenu'
import svgChevronLeft from '@/components/SVG-ChevronLeft'
export default {
  name: 'Archive',
  props: ['doc'],
  data () {
    return {
      loading: true,
      playing: null,
      about: false,
      email: '',
      msg: null,
      info: false
    }
  },
  computed: {
    ...mapState({ archive: state => state.archive }),
    infoPanelButtonLabel () {
      let label = this.doc?.info_panel_button_label || ''
      label = label.length ? label : 'INFO'
      return `${label} +`
    },
    hasInfoPanel () {
      return this.doc && this.$prismic.asText(this.doc.info_panel).length
    }
  },
  methods: {
    onResize: throttle(function () {
      return this.$refs.vimeo?.resizeIframe()
    }, 100),
    onVimeoPlaying (e) {
      if (!e.clip) {
        this.playing = e.playing
      }
    },
    close () {
      // return lastRt?.name ? this.$router.go(-1) : this.$router.push('/')
      this.$router.push('/')
    },
    async subscribe () {
      this.msg = 'Sending...'
      this.msg = await this.$store.dispatch('subscribeToNewsletter', this.email)
    },
    hasText (field) {
      return this.$prismic.asText(field).length
    }
  },
  created () {
    this.$store.dispatch('getArchive')
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },
  components: { svgChevronLeft }
}
</script>

<style>
/*.icon-minus{
  font-size:1.25rem;
  height: 1em;
  width:calc(17/12.5 * 1em);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12.5' viewBox='0 0 17 12.5'%3E%3Cg id='Component_10_92' data-name='Component 10 – 92' transform='translate(0 7)'%3E%3Cpath id='Path_717' data-name='Path 717' d='M0,0H17' fill='none' stroke='white' stroke-width='2'/%3E%3Cpath id='Path_718' data-name='Path 718' d='M0,0H17' transform='translate(0 10.5)' fill='none' stroke='none' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
}*/
</style>
