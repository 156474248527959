<template lang="pug">
  div
    h6.sr-only Accessibility Links
    a.skip-nav(href="#main") Skip to main content
    slot
</template>

<script>
export default {
  name: 'AccessibilityLinks'
}
</script>

<style>
/* Accessibility visible (pulled from Google) */
/*.reader-show{
  clip:rect(1px,1px,1px,1px);
  height:1px;
  margin:0;
  overflow:hidden;
  padding:0;
  position:absolute;
  white-space:nowrap;
  width:1px;
  z-index:-1000
}*/
a.skip-nav {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-nav:focus, a.skip-nav:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid yellow;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}
</style>
