<template>
  <svg style="height:1.4rem" viewBox="0 0 182 17" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M144.4 4.4H138.5V6.3H142.2V14.8H137.6V16.7H148.5V14.8H144.5V4.4H144.4Z" fill="currentColor"/>
    <path d="M126.9 4.2C125.2 4.1 123.6 5.3 123.4 7H123V0H120.7V16.6H123V10C123 6.4 125.3 6.1 126.2 6.1C128.1 6.1 129 7.1 129 9.2V16.7H131.3V10.3C131.4 9 131.2 7.7 130.8 6.4C130 5 128.5 4.1 126.9 4.2Z" fill="currentColor"/>
    <path d="M144.3 0H141.7V2.8H144.3V0Z" fill="currentColor"/>
    <path d="M109 5.9C110.2 5.8 111.4 6.5 111.9 7.6H114.3C113.7 5.3 111.8 4 109.1 4C105.4 4 102.9 6.6 102.9 10.4C102.9 14.3 105.3 16.9 108.9 16.9C111.6 16.9 113.5 15.6 114.2 13.4H111.8C111.3 14.5 110.2 15.1 109 15.1C106.8 15.1 105.3 13.3 105.3 10.4C105.4 7.6 106.8 5.9 109 5.9Z" fill="currentColor"/>
    <path d="M158.7 15H158.3L155 4.4H152.5L157 16.6H160.1L164.5 4.4H162L158.7 15Z" fill="currentColor"/>
    <path d="M181.5 11.1C181.5 6.5 179.4 4 175.6 4C172 4 169.6 6.6 169.6 10.4C169.6 14.4 172 16.9 175.8 16.9C178.5 16.9 180.5 15.6 181.3 13.4H178.8C178.3 14.5 177.1 15.2 175.9 15.1C173.8 15.2 172.1 13.5 172 11.5C172 11.4 172 11.2 172 11.1H181.5ZM171.9 9.2C172.2 7.1 173.5 5.8 175.5 5.8C177.5 5.8 178.7 7 179 9.2H171.9Z" fill="currentColor"/>
    <path d="M6.8 0L0 16.6H2.6L4.2 12.5H12.7L14.3 16.6H16.9L10.5 0H6.8ZM5.1 10.4L8.5 1.6H8.8L12 10.4H5.1Z" fill="currentColor"/>
    <path d="M43.7 4C40 4 37.5 6.5 37.5 10.4C37.5 14.3 40 16.9 43.6 16.9C47.3 16.9 49.7 14.3 49.7 10.4C49.7 6.6 47.3 4 43.7 4ZM43.6 15.1C41.4 15.1 40 13.3 40 10.3C40 7.5 41.4 5.8 43.6 5.8C45.9 5.8 47.3 7.6 47.3 10.4C47.3 13.3 45.8 15.1 43.6 15.1Z" fill="currentColor"/>
    <path d="M27.5 4.9C26.7 5.6 26.5 6.6 26.4 7.9H26.1V4.4H23.9V16.6H26.2V13.1C26.2 9.7 26.6 6.3 30.4 6.3H32.8V4.2H30C29.1 4.2 28.2 4.4 27.5 4.9Z" fill="currentColor"/>
    <path d="M72.3 0L65.5 16.6H68L69.6 12.5H78.1L79.7 16.6H82.3L75.9 0H72.3ZM70.5 10.4L73.9 1.6H74.2L77.4 10.4H70.5Z" fill="currentColor"/>
    <path d="M93 4.9C92.2 5.6 92 6.6 91.9 7.9H91.6V4.4H89.4V16.6H91.7V13.1C91.7 9.7 92.1 6.3 95.9 6.3H98.3V4.2H95.4C94.6 4.2 93.7 4.4 93 4.9Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoAroArchive'
}
</script>

<style>
</style>
