<template lang="pug">
  .h-45.flex.items-center.leading-none.uppercase.px-15.cursor-pointer.whitespace-no-wrap(@click="$emit('click')", :class="{'text-white': isWhite}")
    //- (back btn svg)
    //- button.mr-12(v-if="back", @click.stop="$emit('back')")
      svg-chevron-left.block
    //- home
    router-link.block(:to="{name: 'aro-index'}") Index
    //- crumbs...
    template(v-for="(crumb, i) in crumbs")
      //- chevron
      svg-chevron-left.transform.rotate-180.mx-10(style="height:0.75em")
      //- title
      router-link(v-html="crumb.title", :to="crumb.path")
      //- (inventory count)
      //- span.ml-3.font-tabular(v-if="crumb.inventory && !isNaN(crumb.inventory)", :class="{'text-gray-300': countGray}")
        | {{ crumb.inventory }}
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['crumbs', 'back', 'countGray', 'titleLarge', 'isWhite']
}
</script>

<style>
</style>
