<!-- revised 2021.03.11 slack note - lead under input (no mobile provided) -->
<!-- revision: slacknote "final note" btn fz from base -->
<template lang="pug">
  form.slice-newsletter-signup.bg-black.text-white.px-15.py-8.lg_py-12(@submit.prevent="subscribe")
    .flex
      .flex-1
        //- lead
        prismic-rich-text.mt-3.-mb-4.font-mono.text-mono-10.lg_text-mono-12.truncate.children-inline(:field="slice.primary.lead")
        //- input
        .h-30.lg_h-50.flex.flex-col.justify-center.font-light.uppercase
          //- (input body - collapses if msg)
          .w-full.flex.items-center.justify-between(:class="{'h-0 overflow-hidden': msg}")
            .flex-1.text-25.lg_text-40(style="line-height:0.9")
              //- (hidden label)
              label.sr-only Email
              //- input
              input.-ml-px.block.w-full.bg-black.text-white.uppercase.focus_outline-none(v-model="email", name="email", id="slice-newsletter-signup__input", placeholder="Email", autocomplete="off")
      //- subscribe btn
      button.my-5.uppercase.border.border-gray-300.px-15.focus_outline-none.hover_bg-white.hover_text-black.focus_bg-white.focus_text-black.rounded(type="submit", :class="{'opacity-0': !email.length}") Subscribe

      //- msgs / error
      small.block.w-full.px-15.cursor-pointer(v-show="msg", @click="msg = null") {{ msg }}
</template>

<script>
export default {
  name: 'SliceNewsletterSignUp',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  data () {
    return {
      email: '',
      msg: null
    }
  },
  methods: {
    async subscribe () {
      this.msg = 'Sending...'
      this.msg = await this.$store.dispatch('subscribeToNewsletter', this.email)
    }
  }
}
</script>

<style>
</style>
