<template lang="pug">
  .search-input-row.relative.inset-shadow-t
    //- search row
    form.flex.h-45.items-center.justify-center.pl-15.bg-gray-100(@submit.prevent="submit", validate)
      label.sr-only Search
      //- input
      input.flex-1.uppercase.focus_outline-none.bg-transparent(ref="input", type="search", placeholder="Search", v-model="query", required, @focus="$event => $event.target.select")
      //- clear search
      button.w-45.h-45.flex.items-center.justify-center.cursor-pointer(type="button", @click="clear", v-show="query.length")
        span.icon-menu.icon-menu--close.origin-center(style="transform:scale(0.9,0.9) translateY(1px)")
      //- (screen reader submit)
      button.sr-only(type="submit") Submit

    //- (suggested searches)
    buttons-row-scrollable(v-if="suggestedSearches.length", v-show="suggestedVisible", :items="suggestedSearches", @itemClick="onSuggestedSearchClick")
    //- .h-45(v-if="!query.length")
</template>

<script>
import { mapState } from 'vuex'
import ButtonsRowScrollable from '@/components/ButtonsRowScrollable'
export default {
  name: 'SearchInputRow',
  data () {
    return {
      query: '',
      suggestedVisible: true
    }
  },
  computed: {
    ...mapState({
      suggestedSearches: state => state.aro?.suggested_searches || []
    })
  },
  methods: {
    submit () {
      this.suggestedVisible = false
      this.$root.$emit('newSearch', this.query)
      // let query = {...this.$route.query}
      // query.q = encodeURIComponent(this.query)
      // this.$router.replace({ query })
    },
    hasText () {
      return this.query.length
    },
    async focus () {
      await this.$nextTick()
      const input = this.$el.querySelector('input')
      input.focus()
      // input.select()
    },
    clear () {
      this.query = ''
      this.focus()
      this.$root.$emit('clearSearch')
      this.suggestedVisible = true
      // let query = {...this.$route.query}
      // delete query.q
      // this.$router.replace({ query })
    },
    onSuggestedSearchClick (item) {
      this.query = item.value
      this.submit()
    }
  },
  components: { ButtonsRowScrollable }
}
</script>

<style>
input[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}
</style>
