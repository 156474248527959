<template lang="pug">
  .breadcrumb.lg_sticky.top-45.lg_top-0.left-0.w-full
    //- "is-sticky" pixel observer
    observer.absolute.left-0.h-px.w-px.-top-46.lg_-top-1.bg-black(:threshold="0.01", @visible="onVisiblePixel", @hidden="onHiddenPixel")
    //-
    .h-45.lg_h-60.lg_pt-12.flex.lg_block.items-center.overflow-hidden(:class="theme")
      breadcrumb-text.lg_max-w-33vw(:crumbs="crumbs", :back="back", :class="{'pt-px': isSticky}", :countGray="isSticky", :titleLarge="!isSticky", v-on="$listeners")
      //- .lg_max-w-33vw-off.px-17.flex.items-center.leading-none.uppercase.truncate.cursor-pointer(:class="{'pt-px': isSticky}", @click="onClick")
        //- (back btn svg)
        svg-chevron-left.block.mr-12(v-if="back")
        //- crumbs...
        span.flex.items-start.mr-15(v-for="crumb in crumbs")
          .inline.transition-font-size.duration-150(:class="{'text-28 lg_text-40 lg_leading-none font-light -mt-px lg_-mt-3': !isSticky}", v-html="crumb.title")
          //- superscript
          span.ml-3.font-tabular(v-if="crumb.inventory && !isNaN(crumb.inventory)", :class="{'text-gray-300': isSticky}")
            | {{ crumb.inventory }}
        //- span.flex.items-center
        //-   //- (back btn svg)
        //-   svg-chevron-left.block.mr-12(v-if="back")
        //-   //- main
        //-   .inline.transition-font-size.duration-150(:class="{'text-28 lg_text-40 lg_leading-none font-light -mt-px lg_-mt-3': !isSticky}")
        //-     slot
        //- //- superscript
        //- .ml-5.hidden.lg_inline(:class="{'text-gray-300': isSticky}")
        //-   slot(name="superscript")
</template>

<script>
import { mapGetters } from 'vuex'
import svgChevronLeft from '@/components/SVG-ChevronLeft'
import BreadcrumbText from '@/components/BreadcrumbText'
export default {
  name: 'BreadcrumbSticky',
  props: ['back', 'crumbs'],
  data () {
    return {
      isSticky: true // white bar with larger black text
    }
  },
  computed: {
    ...mapGetters(['is']),
    theme () {
      // product view...
      if (this.$route.name.includes('product')) {
        return {
          // mobile: black on white
          'bg-white text-black': true,
          // lnd-sticky: white on transp
          'lg_text-white lg_bg-transparent': this.isSticky
        }
      }
      // default...
      return {
        // (white bg is from parent component)
        'text-black': !this.isSticky,
        'text-white': this.isSticky
      }
    }
  },
  methods: {
    onHiddenPixel () {
      // set sticky if visible above and document is long enough
      const elIsStick = this.$el.getBoundingClientRect().top <= 45
      const docLongEnough = document.body.scrollHeight > window.innerHeight * 1.2
      if (elIsStick && docLongEnough && this.is('lg')) {
        this.isSticky = true
      }
    },
    onVisiblePixel () {
      this.isSticky = false
    }
    // onClick () {
    //   if (this.back) {
    //     return this.$emit('back')
    //   } else {
    //     this.$root.$emit('collectionsMenuOverlay', true)
    //   }
    // }
  },
  components: { svgChevronLeft, BreadcrumbText }
}
</script>

<style>
/*.breadcrumb-nav{
  & .router-link-exact-active{
    @apply text-black
  }
}*/
/*
.icon-chevron-left{
  height:1em;
  width: calc(8.22/14.319 * 1em);
  background-size: contain;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.22' height='14.319' viewBox='0 0 8.22 14.319'%3E%3Cpath id='Path_1210' data-name='Path 1210' d='M-9379.165,5230.258l6.629,6.629,6.63-6.629' transform='translate(5237.948 9379.695) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
}*/
</style>
