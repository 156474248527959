<template lang="pug">
  ul.variant-options-list.uppercase
    //- options... (exclude season)
    li(v-for="option in variant.selectedOptions", v-if="option.name.toLowerCase() !== 'season'")
      | {{ option.name }}: {{option.value}}
    //- additional items (cart item qty input)
    slot
</template>

<script>
export default {
  name: 'VariantOptions',
  props: ['variant']
}
</script>

<style>
</style>
