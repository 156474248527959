import Vue from 'vue'
import VueGtag from 'vue-gtag'
// handle route changes in router.js afterEach()
// import router from '@/router'

const gaID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID

// honor doNotTrack?
// or is that more for ad-tracking?
// const doNotTrack = window.navigator.doNotTrack === '1'

if (!gaID) {
  console.warn('Missing environment variable: VUE_APP_GOOGLE_ANALYTICS_ID')
} else {
  Vue.use(VueGtag, {
    config: {
      id: gaID,
      anonymize_ip: true
    },
    // disable until user accepts in CookieBanner.vue
    bootstrap: false
  })
}
