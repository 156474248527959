<template lang="pug">
  .slice-collection-synopsis.bg-black.text-black

    //- hero
    .h-screen.relative.overflow-hidden.flex.items-center.justify-center
      //- image / vimeo poster
      resp-img.absolute.overlay(:bg="true", :image="slice.primary.vimeo_poster")
      //- (vimeo)
      template(v-if="vimeoUrl")
        //- play/pause observer
        observer.absolute.overlay(:threshold="0.01", @visible="visible = true", @hidden="visible = false")
        vimeo-player(:vimeoURL="vimeoUrl", bgSize="cover", :visible="visible")
      //- heading
      prismic-rich-text.relative.text-white.text-30.lg_text-60.font-light.text-center.px-17(:field="slice.primary.heading")

    //- details (2col on md)
    .md_flex.w-full.tracking-25.md_border-t.bg-white
      //- description
      prismic-rich-text.slice-collection-synopsis__descrip.flex-1.py-10.px-17.md_order-last.text-18.lg_text-25(:field="slice.primary.description")
      //- inventory
      //- ul.flex-1.p-17.border-t.md_border-t-0.uppercase
        h6.flex.justify-between.mb-12.md_mb-lh
          | Inventory of Items
          span #

        template(v-if="collection")
          //- "all"
          li.leading-underline.pb-2.flex.justify-between.group.text-black
            router-link.btn-theme-text(:to="{name: 'aro-collection', params: {collection: collection.uid}}") All
            span.font-tabular {{ collection._inventory }}

          //- "type" tags of linked collection...
          li.leading-underline.pb-2.flex.justify-between.group.text-black(v-for="item in collectionTypes")
            router-link.btn-theme-text(:to="{name: 'aro-collection', params: {collection: collection.uid}, query: { type: item.slug }}") {{ item.name }}
            span.font-tabular {{ ('00' + item.inventory).slice(-3) }}

        //- slice items... (linked collections)
        li.leading-underline.pb-2.flex.justify-between.group.text-black(v-for="item in slice.items")
          app-link.btn-theme-text(:link="item.collection_link") {{ $prismic.asText(item.collection_link.data.title) }}
          span.font-tabular {{ inventory(item.collection_link.uid) }}

        //- li.leading-underline.pb-2.flex.justify-between.group.text-gray-300(v-for="item in slice.items", v-if="col(item)")
          router-link.text-black.btn-theme-text(:to="{name: 'aro-collection', params: {collection: col(item).uid}}")
            | {{ $prismic.asText(col(item).data.title) }}
          | {{ count(col(item)._inventory) }}
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceCollectionSynopsis',
  props: {
    slice: Object
  },
  data () {
    return {
      visible: true
    }
  },
  computed: {
    ...mapState(['aro']),
    // ...mapGetters(['inventory']),
    vimeoUrl () {
      return this.slice?.primary?.vimeo?.url
    },
    collection () {
      return this.$store.state.collections.find(doc => doc.uid === this.slice.primary.collection_link?.uid)
    }
    // collectionTypes () {
    //   return (this.collection._tags || []).filter(tag => tag.group === 'type')
    // }
  }
  // methods: {
  //   col (item) {
  //     const id = item.collection_link?.id
  //     return id && this.$store.state.collections.find(col => col.id === id)
  //   },
  //   count (num) {
  //     return num >= 1000 ? num : ('00' + num).slice(-3)
  //   }
  // }
}
</script>

<style lang="postcss">
.slice-collection-synopsis__descrip{
  & h5 {
    @apply text-25;
  }
  & p + p {
    marign-top: calc(32/25 * 1em);
  }
}

@screen lg {
  .slice-collection-synopsis__descrip{
    & h5 {
      @apply text-40;
    }
  }
}
</style>
