import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from '@/plugins/prismic/link-resolver'
import './plugins/register-vue-components.js'
import 'lazysizes'
import VueMeta from 'vue-meta'
import './plugins/vue-gtag.js'

Vue.use(PrismicVue, {
  endpoint: `https://${process.env.VUE_APP_PRISMIC_REPO}.cdn.prismic.io/api/v2`,
  linkResolver
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// netlify pre-rendering, manually set false/true when ready if having issues
// window.prerenderReady = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
