const Product = () => import(/* webpackChunkName: "product" */ '../views/product/Product.vue')

const sitemapPrismicClient = async () => {
  const Prismic = await import('@prismicio/client')

  const client = Prismic.client(`https://${process.env.VUE_APP_PRISMIC_REPO}.cdn.prismic.io/api/v2`)

  return [client, Prismic]
}

const getLastMod = (dateString) => new Date(dateString).toISOString().split('T').shift()

export const routes = [
  // preview prismic docs
  {
    path: '/preview',
    name: 'preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },

  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },

  // archive / services
  {
    path: '/archive',
    name: 'archive',
    component: () => import(/* webpackChunkName: "archive" */ '../views/Archive.vue'),
    meta: { wash: 'black' }
  },
  // archive pages
  {
    path: '/archive/:archivePage',
    name: 'archive-page',
    component: () => import(/* webpackChunkName: "archive-page" */ '../views/ArchivePage.vue'),
    meta: {
      wash: 'black',
      sitemap: {
        slugs: async () => {
          const [client, Prismic] = await sitemapPrismicClient()

          const { results } = await client.query(Prismic.Predicates.at('document.type', 'archive_page'))

          return results.map(({ uid, last_publication_date: updatedAt }) => ({ archivePage: uid, lastmod: getLastMod(updatedAt) }))
        }
      }
    }
  },

  // aro / shop
  {
    path: '/aro',
    component: () => import(/* webpackChunkName: "aro" */ '../views/Aro.vue'),
    // meta: { landing: true },
    children: [
      {
        path: '',
        name: 'aro-index',
        component: () => import(/* webpackChunkName: "aro" */ '../views/AroIndex.vue')
      },
      {
        path: '/products/:product',
        name: 'aro-product',
        component: Product,
        meta: {
          sitemap: {
            slugs: async () => {
              const { shopify, queryProducts } = await import('../store/shopify-queries')

              const products = await shopify({
                query: queryProducts(),
                variables: { first: 250 }
              })

              return products.data.products.edges.map(({ node: { handle, updatedAt } }) => ({ product: handle, lastmod: getLastMod(updatedAt) }))
            }
          }
        }
      },
      {
        path: '/collections/:collection',
        name: 'aro-collection',
        component: () => import(/* webpackChunkName: "collection" */ '../views/Collection.vue'),
        props: true,
        meta: {
          sitemap: {
            slugs: async () => {
              const [client, Prismic] = await sitemapPrismicClient()

              const { results } = await client.query(Prismic.Predicates.at('document.type', 'collection'))

              return results.map(({ uid, last_publication_date: updatedAt }) => ({ collection: uid, lastmod: getLastMod(updatedAt) }))
            }
          }
        }
      },
      {
        path: '/collections/:collection/products/:product',
        name: 'aro-collection-product',
        component: Product,
        meta: {
          sitemap: {
            slugs: async () => {
              const [client, Prismic] = await sitemapPrismicClient()

              const { results } = await client.query(Prismic.Predicates.at('document.type', 'collection'))

              return results.map(({ data, uid }) => data.body.filter(({ slice_type: slice }) => slice === 'product').filter(({ primary }) => primary.shopify_product).map(({ primary }) => ({
                collection: uid,
                product: primary.shopify_product.handle,
                lastmod: getLastMod(primary.shopify_product.updated_at)
              }))).flat()
            }
          }
        }
      },
      {
        path: '/:page',
        name: 'aro-page',
        component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
        meta: {
          sitemap: {
            slugs: async () => {
              const [client, Prismic] = await sitemapPrismicClient()

              const { results } = await client.query(Prismic.Predicates.at('document.type', 'page'))

              return results.map(({ uid, last_publication_date: updatedAt }) => ({ page: uid, lastmod: getLastMod(updatedAt) }))
            }
          }
        }
      }
    ]
  }
]
