<template lang="pug">
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1459 510" preserveAspectRatio>
    <g fill="currentColor">
      <path d="M1271,127c-114,0-187,75-187,191s74,192,188,192c115,0,187-77,187-192S1386,127,1271,127z M1269,452c-66,0-106-53-106-135c0-83,41-132,107-132c67,0,110,52,110,133S1337,452,1269,452z"/>
      <path d="M207,0L0,500h81l47.8-123h258.4L433,500h82L317,0H207z M154,312L255,52h11l96.9,260H154z"/>
      <path d="M777,242h-11V137h-70v363h74V396c0-99,10-200,127-200h75v-64h-89C812,132,778,167,777,242z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped>
svg{
  height:1em;
  width:calc(47/17*1em)
  & *{
    transition:fill 200ms;
  }
}
</style>
