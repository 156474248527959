<template lang="pug">
  .slice-tiles.flex.text-white.md_font-light.text-18.md_text-40
    app-link.w-1x2.group(v-for="(item, i) in slice.items", :link="item.link", :class="{'hidden md_block': !item.mobile_visibility, 'md_w-1x3': slice.items.length > 2}", v-if="i < 3")
      .relative.w-full(:class="[aspectRatio]")
        resp-img(:bg="true", :image="item.image")
        h6.absolute.top-0.left-0.w-full.px-15.md_px-17.pt-10.md_pt-8.uppercase
          span.hidden.group-hover_inline Shop&nbsp;
          | {{ item.link_text }}
</template>

<script>
export default {
  name: 'SliceTiles',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) }
  },
  computed: {
    aspectRatio () {
      return this.slice.items.length >= 3 ? 'pb-ar-2x3'
        : 'pb-ar-3x4'
    }
  }
}
</script>

<style>
.slice-tiles a:hover img{
  filter:invert(100%);
}
</style>
